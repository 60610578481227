// 配种
export const breed_zh = {
    way: "配种方式",
    way_1: "人工受精",
    way_2: "自然交配",
    frozenSemenNumber: "冻精号",
    matingLabelNumber: "公牛号",
    breedingTime: "配种日期",
    sceneOperator: "配种员"
}

export const breed_en = {
    way: "Breeding Method",
    way_1: "Artificial Insemination",
    way_2: "Natural Mating",
    frozenSemenNumber: "Frozen Semen Number",
    matingLabelNumber: "Bull Tag Number",
    breedingTime: "Breeding Date",
    sceneOperator: "Breeding Technician"
}