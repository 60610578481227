export const web_user_zh = {
    username:"用户名",
    password:"密码",
    name:"姓名",
    mobile:"手机号码",
    email:"电子邮箱",
    groupCode:"所属机构",
    appUserId:"关联APP账号",
    farmIds:"所属养殖场",
    insuranceCompanyId:"所属保险公司",
    guaranteeCompanyId:"所属担保公司",
    bankIds:"所属银行",
    roleList:"权限角色",
    roleList2:"权限角色：",
    status:"启用状态",
    status_0:"启用",
    status_1:"禁用",
    status_2:"锁定",
    status_3:"注销",
    dialog_title:"请选择贷款银行",
    dialog_tip:"温馨提示",
    dialog_tip1:"银行角色",
    dialog_tip2:"1、无需选择所属养殖场，所属保险公司和所属机构",
    dialog_tip3:"2、分配银行为总行或者分行可查看总/分行专属大屏",
    dialog_tip4:"3、分配银行为支行可查看支行专属大屏",
    dialog_tip5:"保险角色",
    dialog_tip6:"1、无需选择所属养殖场，所属银行和所属机构",
    place_1:"请输入用户名",
    place_2:"用户名必须为字母或数字，长度最长为30",
    place_3:"请输入密码",
    place_4:"长度在 6 到 16 个字符",
    place_5:"请选择所属机构",
    place_6:"请输入员工姓名",
    place_7:"请输入手机号码",
    place_8:"手机号码输入错误",
    place_9:"请输入电子邮箱",
    place_10:"电子邮箱输入错误",
    place_11:"点击选择贷款银行",
    place_12:"无法修改用户名",
    wu:"无",
    addUser:"新增用户",
    addTime:"创建时间",
    lockTime:"锁定/停用时间",
    resetPassword:"重置密码",
    loginUnlock:"登录解锁",
    IPrecord:"登录IP记录",
    not_select_user:'你没有选中用户!',
    length_not_match:"密码长度不符合",
    to_unlock:"是否确认解锁？",
    Tips:'提示',
    supplier:'关联供应商'
}
export const web_user_en = {
    username:"Username",
    password:"Password",
    name:"Name",
    mobile:"Mobile",
    email:"Email",
    groupCode:"Group",
    appUserId:"App User",
    farmIds:"Farms",
    insuranceCompanyId:"Insurance Company",
    guaranteeCompanyId:"Guarantee Company",
    bankIds:"Bank",
    roleList:"Role",
    roleList2:"Role:",
    status:"Status",
    status_0:"Enable",
    status_1:"Disable",
    status_2:"Lock",
    status_3:"Logout",
    dialog_title:"Select Bank",
    dialog_tip:"Tips",
    dialog_tip1:"Bank Role",
    dialog_tip2:"1. No need to select the farm, insurance company and organization",
    dialog_tip3:"2. The bank is the head office or branch office, you can view the exclusive screen of the head/branch office",
    dialog_tip4:"3. The bank is a sub-branch, you can view the exclusive screen of the sub-branch",
    dialog_tip5:"Insurance Role",
    dialog_tip6:"1. No need to select the farm, bank and organization",
    place_1:"Please enter the username",
    place_2:"The username must be a letter or number, and the length is up to 30",
    place_3:"Please enter the password",
    place_4:"The length is 6 to 16 characters",
    place_5:"Please select the group",
    place_6:"Please enter the employee name",
    place_7:"Please enter the mobile",
    place_8:"The mobile number is entered incorrectly",
    place_9:"Please enter the email",
    place_10:"The email is entered incorrectly",
    place_11:"Click to select the loan bank",
    place_12:"Cannot modify the username",
    wu:"None",
    addUser:"add User",
    addTime:"add Time",
    lockTime:"Lock/disable time",
    resetPassword:"Reset password",
    loginUnlock:"Login unlock",
    IPrecord:"IP record",
    not_select_user:'You did not select the user!',
    length_not_match:"The password length does not match",
    to_unlock:"Do you want to unlock it?",
    Tips:'Tips',
    supplier:'Associated Supplier'
}