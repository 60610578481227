export const label_info_zh = {
    registerName: "戴标员",
    url: "戴标照片",
    label_btn:"耳标登记",
    batch_del_btn:"批量删除",
    no_photo:"暂无照片",
    reg_img:"登记照片",
    reg_info:"登记疫苗注射情况",
    preventionLabel:"防疫编号",
    selectFile:"选择文件",
    place_1:"若没有导入模板，请下载此处",
    place_2:"下载模板",
    place_3:"确认上传",
    place_4:"请确认是否删除耳标",
    place_5:"以下耳标有业务数据存在，不可删除",
    place_6:"添加成功",
    place_7:"请选择文件",
    place_8:"请勾选耳标号后再批量删除",
    success:"成功",
    del_success:"删除成功",
    place_10:"当前耳标存在业务数据记录，不允许删除，请联系工作人员！",
    place_11:"请选择养殖场才能导出数据",
    place_12:"删除提示",
    importError:"导入失败",
    basicInfo:"基础信息",
    selectFarm:"养殖场选择",
    infoPlace1:"请选择养殖场",
    houseId:"养殖舍选择",
    houseIdPlace:"请选择养殖舍",
    addhouse:"+添加养殖舍",
    breedId:"养殖品种",
    breedIdPlace:"请选择养殖品种",
    typeConfId:"养殖类别",
    typeConfIdPlace:"请选择养殖类别",
    VaccinesConf:"疫苗注射情况",
    detailAddress:"选择栏位",
    type_reg1:"手动登记耳标",
    type_reg2:"批量登记耳标",
    age:"平均日龄",
    agePlace:"请输入平均日龄",
    day:"天",
    weight:"平均体重",
    weightPlace:"请输入平均体重",
    jin:"斤",
    column:"号栏",
    regNext:"+登记下一个",
    batchPost:"批量上传",
    uploadFile:"上传文件",
    uploadPlace1:"共上传耳标",
    ge:"个",
    uploadPlace2:"支持扩展名",
    uploadPlace3:"若没有导入模板，请下载此处",
    uploadPlace4:"下载模板",
    submit:"提交登记",
    tipPlace1:"请输入2-10个字之间，不能包含特殊字符",
    tipPlace2:"请填写平均日龄和平均体重",
    tipPlace3:"生物芯片耳标号不能为空",
    tipPlace4:"生物芯片耳标号重复",
    tipPlace5:"文件中生物芯片耳标号重复",
    tipPlace6:"批量导入成功",
    tipPlace7:"文件格式错误，请重新选择文件！",
    tipPlace8:"文件不能为空，请重新选择文件！",
    tipPlace9:"请选择养殖场",
    tipPlace10:"请选择养殖舍",
    tipPlace11:"请选择养殖类别",
    tipPlace12:"请选择养殖品种",

}
export const label_info_en = {
    registerName: "Register Name",
    url: "Register Photo",
    label_btn:"Label Registration",
    batch_del_btn:"Batch Delete",
    no_photo:"No Photo",
    reg_img:"Registration Photo",
    reg_info:"Registration Vaccine Injection Information",
    preventionLabel:"Prevention Number",
    selectFile:"Select File",
    place_1:"If there is no import template, please download here",
    place_2:"Download Template",
    place_3:"Confirm Upload",
    place_4:"Please confirm whether to delete the ear tag",
    place_5:"The following ear tags have business data and cannot be deleted",
    place_6:"Add success",
    place_7:"Please select a file",
    place_8:"Please check the ear tag number before batch deletion",
    success:"Success",
    del_success:"Delete Success",
    place_10:"The current ear tag has business data records and cannot be deleted. Please contact the staff!",
    place_11:"Please select a farm to export data",
    place_12:"Delete Prompt",
    importError:"Import Failed",
    basicInfo:"Basic Information",
    selectFarm:"Select Farm",
    infoPlace1:"Please select a farm",
    houseId:"Select Breeding House",
    houseIdPlace:"Please select a breeding house",
    addhouse:"+Add Breeding House",
    breedId:"Breeding Variety",
    breedIdPlace:"Please select a breeding variety",
    typeConfId:"Breeding Category",
    typeConfIdPlace:"Please select breeding category",
    VaccinesConf:"Vaccine Injection Information",
    detailAddress:"Select Column",
    type_reg1:"Manual registration of ear tags",
    type_reg2:"Batch registration of ear tags",
    age:"Average age",
    agePlace:"Please enter the average age",
    day:"Day",
    weight:"Average weight",
    weightPlace:"Please enter the average weight",
    jin:"Jin",
    column:"Column",
    regNext:"+Register Next",
    batchPost:"Batch Upload",
    uploadFile:"Upload File",
    uploadPlace1:"Uploaded ear tags",
    ge:"Ge",
    uploadPlace2:"Support extension name",
    uploadPlace3:"If there is no import template, please download here",
    uploadPlace4:"Download Template",
    submit:"Submit Registration",
    tipPlace1:"Please enter between 2-10 characters, can not contain special characters",
    tipPlace2:"Please fill in the average age and average weight",
    tipPlace3:"Biochip ear tag number cannot be empty",
    tipPlace4:"Biochip ear tag number duplicate",
    tipPlace5:"Biochip ear tag number duplicate in file",
    tipPlace6:"Batch import success",
    tipPlace7:"File format error, please reselect the file!",
    tipPlace8:"The file cannot be empty, please reselect the file!",
    tipPlace9:"Please select a farm",
    tipPlace10:"Please select a breeding house",
    tipPlace11:"Please select breeding category",

}