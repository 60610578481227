// 档案模块国际化
export const archives_zh = {
  variety: '品种',
  Types: '类型',
  File_details: '档案详情',
  Categories: '类别',
  Bid_holder: '戴标人',
  Marking_time: '戴标时间',
  Wear_mark_photo: '戴标照片',
  Pedigree_information: '系谱信息',
  Family_tree: '系谱',
  father: '父亲:',
  mother: '母亲:',
  grandfather: '祖父:',
  grandmother: '祖母:',
  Maternal_grandfather: '外祖父:',
  Maternal_grandmother: '外祖母:',
  Great_grandfather: '曾祖父:',
  Great_grandmother: '曾祖母:',
  Great_grandfather_mother: '曾外祖父:',
  Great_grandmother_mother: '曾外祖母:',
  Maternal_great_grandfather: '外曾祖父:',
  Maternal_great_grandmother: '外曾祖母:',
  My_great_great_grandfather: '外曾外祖父:',
  My_great_great_grandmother: '外曾外祖母:',
  No_information_yet: '暂无信息',
  Record_of_file: '档案记录',
  Immunization_Registration_Information: '免疫登记信息',
  Treatment_Registration_Information: '治疗登记信息',
  Estrus_Registration_Information: '发情登记信息',
  Breeding_Registration_Information: '配种登记信息',
  Pregnancy_Test_Registration_Information: '孕检登记信息',
  Childbirth_Registration_Information: '分娩登记信息',
  Immunization_date: '免疫日期',
  Epidemic_prevention_officer: '防疫员',
  Vaccine_name: '疫苗名称',
  Medication_pattern: '疫苗用药方式',
  dose: '药量',
  manufacturer: '生产厂家',
  Manufacturer_lot_number: '厂家批号',
  registrant: '录入人',
  Registration_time: '录入时间',
  Treatment_date: '治疗日期',
  Veterinarian: '兽医',
  Drug_name: '药品名称',
  Charge_quantity: '药量',
  Drug_manufacturer: '药品生产厂家',
  Name_of_disease: '疾病名称',
  Estrus_date: '发情日期',
  Phenomenon_description: '现象描述',
  Estrus_type: '发情类别',
  Simultaneous_estrus: '同期发情',
  Natural_estrus: '自然发情',
  Date_of_breeding: '配种日期',
  matcher: '配种员',
  Breeding_pattern: '配种方式',
  Frozen_semen: '冻精号',
  Oxer: '公牛号',
  Pregnancy_test_date: '孕检日期',
  Pregnancy_test_result: '孕检结果',
  child_care_worker: '保育员',
  Delivery_date: '分娩日期',
  Delivery_outcome: '分娩结果',
  normal: '正常',
  abnormal: '异常',
  Number_of_births: '分娩数量',
  Delivery_mode: '分娩方式',
  Calf_weight: '犊牛重量(公斤)',
  Electronic_ear_tag_for_calves:'犊牛电子耳标号',
  head:'头',
  View_calf_information:'查看犊牛信息',
  No_image_information_available:'暂无图片信息',
  Female_button_number:'母扣号',
  weight:'重量',


}

export const archives_en = {
  variety: 'variety',
  Types: 'Types',
  File_details: 'File details',
  Categories: "Categories",
  Bid_holder: 'Bid holder',
  Marking_time: 'Marking time',
  Wear_mark_photo: 'Wear mark photo',
  Pedigree_information: 'Pedigree information',
  Family_tree: 'Family tree',
  father: 'father:',
  mother: 'mother:',
  grandfather: 'grandfather:',
  grandmother: 'grandmother:',
  Maternal_grandfather: 'Maternal grandfather:',
  Maternal_grandmother: 'Maternal grandmother:',
  Great_grandfather: 'Great grandfather:',
  Great_grandmother: 'Great grandmother:',
  Great_grandfather_mother: 'Great grandfather mother:',
  Great_grandmother_mother: 'Great grandmother mother:',
  Maternal_great_grandfather: 'Maternal great grandfather:',
  Maternal_great_grandmother: 'Maternal_great grandmother:',
  My_great_great_grandfather: 'My great great grandfather:',
  My_great_great_grandmother: 'My great great grandmother:',
  No_information_yet: 'No information yet',
  Record_of_file: 'Record of file',
  Immunization_Registration_Information: 'Immunization',
  Treatment_Registration_Information: 'Treatment',
  Estrus_Registration_Information: 'Estrus',
  Breeding_Registration_Information: 'Breeding',
  Pregnancy_Test_Registration_Information: 'Pregnancy Test',
  Childbirth_Registration_Information: 'Childbirth',
  Immunization_date: 'Immunization date',
  Epidemic_prevention_officer: 'Epidemic prevention officer',
  Medication_pattern: 'Medication pattern',
  dose: 'dose',
  manufacturer: 'manufacturer',
  Manufacturer_lot_number: "Manufacturer's lot number",
  registrant: 'registrant',
  Registration_time: 'Registration time',
  Vaccine_name: 'Vaccine name',
  Treatment_date: 'Treatment date',
  Veterinarian: 'Veterinarian',
  Drug_name: 'Drug name',
  Charge_quantity: 'Charge quantity',
  Drug_manufacturer: 'Drug manufacturer',
  Name_of_disease: 'Name of disease',
  Estrus_date: 'Estrus date',
  Phenomenon_description: 'Phenomenon description',
  Estrus_type: 'Estrus type',
  Simultaneous_estrus: 'Simultaneous estrus',
  Natural_estrus: 'Natural estrus',
  Date_of_breeding: 'Date of breeding',
  matcher: 'matcher',
  Breeding_pattern: 'Breeding pattern',
  Frozen_semen: 'Frozen semen',
  Oxer: 'Oxer',
  Pregnancy_test_date: 'Pregnancy test date',
  Pregnancy_test_result: 'Pregnancy test result',
  child_care_worker: 'Child-care worker',
  Delivery_date: 'Delivery date',
  Delivery_outcome: 'Delivery outcome',
  normal: 'Normal',
  abnormal: 'Abnormal',
  Number_of_births: 'Number of births',
  Delivery_mode: 'Delivery mode',
  Calf_weight: 'Calf weight(Kg)',
  Electronic_ear_tag_for_calves:'Electronic ear tag for calves',
  head:'head',
  View_calf_information:'View calf information',
  No_image_information_available:'No image information available',
  Female_button_number:'Female button number',
  weight:'weight',
}