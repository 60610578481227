






































































































































// @ is an alias to /src
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vTags from "@/components/Tags.vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { Login, LoginUserMerchants } from "@/common/login/login";
import { AxiosData } from "@/common/http/http";
import { mapActions } from "vuex";
import { getTurnUrl } from "@/api";
interface menusList {
    url: string;
    name: string;
    children: [];
    id: string;
}
@Component({
    components: {
        "v-tags": vTags,
    },
})
export default class HelloWorld extends Vue {
    private menusList: menusList[] = [];
    private name: string = "";

    private mchId: string = "";
    private created() {
        this.name = localStorage.getItem("name")!;

        // 按钮权限
        const menus: any[] = JSON.parse(localStorage.getItem("menus")!);
        // console.log(menus);
        const deepLoop: DeepLoop = new DeepLoop();
        const menusData = deepLoop.getResources(menus);
        // console.log(menusData);
        this.$store.commit("setMenusButton", menusData);
        // end

        //    菜单权限
        const anies = menus.filter(
            (value) => value.url === "/bigData" || value.url === "/bigDataByPro"
        );
        if (anies.length === 2) {
            localStorage.setItem("bigDataChange", "11");
        } else {
            localStorage.removeItem("bigDataChange");
        }
        this.menusList = menus;

        //    end

        // this.getCurrentMerchants();

        if (localStorage.getItem("showMerchantsSelectionBox")) {
            this.showMerchantsSelectionBox = true;
        }
        // for(let i in this.menusList){
        //     if(this.menusList[i].id == localStorage.getItem('stairMenu')){
        //         this.childrenList = this.menusList[i].children
        //     }
        // }
        // this.changeChildren = this.menusList[1].children[0].id
        // for (const i in menus) {
        //     for (const a in menus[i].children){
        //         if(menus[i].children[a].url == this.$route.path){
        //             console.log(menus[i].children[a])
        //             this.changeMenus = menus[i].id
        //             this.childrenList = menus[i].children
        //             this.changeChildren = menus[i].children[a].id
        //         }
        //     }
        // }
    }

    private showMerchantsSelectionBox: boolean = false;

    private loginUserMerchantsList: LoginUserMerchants[] = [];
    // private getCurrentMerchants(): void {
    //     localStorage.setItem('selectMchId', '');
    //
    //     this.logout.getCurrentMerchants((data: AxiosData<LoginUserMerchants[]>)=>{
    //         this.loginUserMerchantsList = data.data;
    //     });
    // }

    private logout: Login;

    private changSelect(): void {
        localStorage.setItem("selectMchId", this.mchId);
        this.showPage = false;
        setTimeout(() => {
            this.showPage = true;
        });
    }
    constructor() {
        super();
        this.logout = new Login();
    }
    // 用户名下拉菜单选择事件
    private handleCommand(command: string): void {
        if (command == "loginout") {
            this.logout.logout();
            localStorage.clear();
            this.$router.push("/login");
        } else if (command == "changeLanuage_zh") {
            this.changeLanuage("zh");
        } else if (command == "changeLanuage_en") {
            this.changeLanuage("en");
        }
    }
    // end
    private homeTitle = "";
    private homeTitleType = 1;
    @Watch("homeTitle")
    refreshHomeTitles(newVal: any) {
        console.log(newVal, "newVal...5646554");

        if (newVal === "") {
            this.refreshHomeTitle();
        }
    }
    private refreshHomeTitle() {
        setTimeout(() => {
            this.homeTitle = localStorage.getItem("homeTitle") || "";
            this.homeTitleType =  Number(localStorage.getItem("homeTitleType")) || 1;
        }, 0);
    }
    public mounted(): void {
        // if(localStorage.getItem('stairMenu') == this.menusList[0].id){

        //     this.changeMenus = this.menusList[1].id
        //     this.childrenList = this.menusList[1].children
        //     this.changeChildren = this.menusList[1].children[0].id
        // }else{
        //    this.changeMenus = localStorage.getItem('stairMenu')
        //    this.changeChildren = localStorage.getItem('childrenMenu')
        //    for(let i in this.menusList){
        //         if(this.menusList[i].id == localStorage.getItem('stairMenu')){
        //             this.childrenList = this.menusList[i].children
        //         }
        //     }
        // }

        this.refreshHomeTitle();
        let nub = 0;
        let count = 10;
        for (let i in this.menusList) {
            count--;
            for (let a in this.menusList[i].children) {
                count++;
                // console.log(this.menusList[i].children)
                // if(nub == count){
                //     console.log(2132451)
                // }
                if (this.menusList[i].children[a].url == this.$route.path) {
                    this.childrenList = this.menusList[i].children;
                    this.changeMenus = this.menusList[i].id;
                    this.changeChildren = this.menusList[i].children[a].id;
                } else {
                    count--;
                    // console.log(nub)
                    // console.log(count)
                }
                if (count < 1 && this.childrenList.length == 0) {
                    this.getMenusList();
                    // console.log(123457)
                }
            }
        }

        // this.changeMenus = this.menusList[1].id
        // this.childrenList = this.menusList[1].children
        // this.changeChildren = this.menusList[1].children[0].id

        // this.childrenList = this.$store.state.childrenList
        // this.changeMenus = this.$store.state.changeMenus
    }

    private getMenusList(): void {
        this.changeMenus = <string>localStorage.getItem("stairMenu");
        this.changeChildren = <string>localStorage.getItem("childrenMenu");
        for (let i in this.menusList) {
            if (this.menusList[i].id == localStorage.getItem("stairMenu")) {
                this.childrenList = this.menusList[i].children;
            }
        }
    }

    @Watch("$route")
    $setMenusList(): void {
        // console.log(this.$route);
        // console.log(this.menusList);
        for (let i in this.menusList) {
            // if(this.menusList[i].id == localStorage.getItem('stairMenu')){
            //     this.childrenList = this.menusList[i].children
            // }
            for (let a in this.menusList[i].children) {
                // console.log(this.menusList[i].children);
                if (this.menusList[i].children[a].url == this.$route.path) {
                    // console.log(this.menusList[i].children);
                    // console.log(this.menusList[i].id);
                    // console.log(this.menusList[i].children[a].id);
                    this.childrenList = this.menusList[i].children;
                    this.changeMenus = this.menusList[i].id;
                    this.changeChildren = this.menusList[i].children[a].id;
                }
            }
        }
    }

    private showPage: boolean = true;

    public changeMenus: string = "";
    public changeChildren: string = "";

    public childrenList: any[] = [];
    private handleMenus(obj: any, nub: string): void {
        // console.log(obj);
        // this.childrenList = obj.children
        // this.changeMenus = obj.id
        this.$store.commit("setChangeMenus", obj.id);
        this.$store.commit("setChildrenList", obj.children);
        this.childrenList = this.$store.state.childrenList;
        this.changeMenus = this.$store.state.changeMenus;
        // console.log(this.$store.state.changeMenus);
        // console.log(this.$store.state.childrenList);
        localStorage.setItem("stairMenu", obj.id);
    }

    private handleChildren(obj: any, nub: string): void {
        this.$router.push(obj.url);
        this.changeChildren = obj.id;
        localStorage.setItem("childrenMenu", obj.id);
    }

    private handleSelect(router: any, arr: any, vc: any) {
        if (router === "/ProductionManagementPlatform") {
            // /productionAuth/getTurnUrl
            getTurnUrl((res: any) => {
                const { code, data } = res;
                if (code === 200) window.open(data, "_blank");
                console.log(res);
            });
            // window.open("http://beef.yimucloud.com/#/login", "_blank");
            return;
        } else {
            this.$router.push(router);
        }
    }
    //  newVal[0] == '电'
    //  newVal[1] == '信'

    //根据用户名称的前两个字 判断是否为联通  如果为联通那么替换左上角logo图片 以及class style样式
    private showNewLog: any = 11;
    @Watch("name")
    private changeName(newVal: any) {
        if (newVal[0] == "移") {
            if (newVal[1] == "动") {
                this.showNewLog = 12;
                return;
            } else {
                this.showNewLog = 11;
            }
        } else {
            this.showNewLog = 11;
        }

        if (newVal[0] == "联") {
            if (newVal[1] == "通") {
                this.showNewLog = 13;
                return;
            } else {
                this.showNewLog = 11;
            }
        } else {
            this.showNewLog = 11;
        }

        if (newVal[0] == "电") {
            if (newVal[1] == "信") {
                this.showNewLog = 14;
                return;
            } else {
                this.showNewLog = 11;
            }
        } else {
            this.showNewLog = 11;
        }
    }

    // private updateLanuage = mapActions(['updateLanuage'])
    // 切换语言
    private changeLanuage(lang: string) {
        this.$store.dispatch("updateLanuage", lang);
        const { path } = this.$router.currentRoute;
        new Login().getCurrentMenu(() => {}, 1, path);
    }
}
