






import { Component, Vue } from "vue-property-decorator";
import { Login } from "@/common/login/login";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

const aes = require("@/common/bin/jsencrypt.js");

@Component
export default class App extends Vue {
    private appHeight: number = 0;

    private created() {
        
        const lang = this.$store.state.lanuage;
        this.appHeight = parseInt(localStorage.getItem("clientHeight")!);
        const language = navigator.language;
        console.log(language, "language");
        if (lang) {
            console.log(lang, "lang");
            this.$store.dispatch("updateLanuage", this.$store.state.lanuage);
        } else {
            console.log(lang, "nolang");
            if (language !== "zh-CN" && language !== "zh") {
                this.$store.dispatch("updateLanuage", "en");
            } else {
                this.$store.dispatch("updateLanuage", "zh");
            }
        }
        // if(performance.navigation.type === 1) {
        //     this.$store.commit('setIsTimer');
        //     const refreshToken: Login = new Login();
        //     refreshToken.setRefreshToken();
        // }
        sessionStorage.clear();
        localStorage.removeItem("selectMchId");
        if (localStorage.getItem("menus")) {
            const menus = JSON.parse(localStorage.getItem("menus")!);

            const resourceCodes = new DeepLoop().getResources(menus);
            // console.log(resourceCodes);
            this.$store.commit("setResourceCodes", resourceCodes);
        }
    }
}
