export const login_zh = {
    plantform: "畜牧数智化综合服务平台",
    login: "登 录",
    user: "用户名",
    psd: "密码",
    enteruser: "请输入用户名",
    enterpsd: "请输入密码",
    no_root: "暂无任何权限，请联系管理员添加权限",

}
export const login_en = {
    plantform: "Livestock Service Platform",
    login: "Login",
    user: "User",
    psd: "Password",
    enteruser: "Please enter the user name",
    enterpsd: "Please enter the password",
    no_root: "No permission, please contact the administrator to add permission",
}