export const labelnumber_zh = {
    isBind:"绑定状态",
    reason:"原因",
    isBind_1:"已绑定",
    isBind_0:"未绑定",
    tagType:"耳标类型",
    tagNumber:"耳标号",
    enterTagNumber:"请输入耳标号",
    other:"其他",
    tagStatus:"低电报警",
    lowPower:"低电",
    normal:"正常",
    time:"登记时间",
    addTag:"添加耳标",
    batchTag:"批量添加",
    delBatchTag:"批量删除",
    jdFarm:"京东农牧",
    registerTime:"绑定时间",
    registerUserName:"戴标人",
    createTime:"创建时间",
    createUserName:"创建人",
    lowBatteryTime:"预警时间",
    dialogTitle:"标签分组（请选择标签进行分组）",
    dialogTitle_1:"一键分组",
    dialogTitle_2:"导入分组",
    upload:"上传",
    dialogTip:"若没有导入模板，请下载此处",
    downloadModel:"下载模板",
    optionshell:"操作界面",
    custNo:"客户编号",
    custName:"客户名称",
    batchImport:"批量导入",
    yes:"是",
    no:"否",
    noFileTip:"请上传文件",
    linkCompany:"关联企业",
    enterTag:"请输入耳标号",
    selectCompany:"请选择企业",
    submitTip_1:"不能删除已绑定的耳标号",
    submitTip_2:"此操作将删除耳标,是否继续?",
    submitTip_3:"提示",
    submitTip_4:"删除成功",
    submitTip_5:"请勾选耳标号再进行批量删除",
    submitTip_6:"添加成功",
    submitTip_7:"请勾选数据后再操作",
    submitTip_8:"当前选中设备已绑定养殖场，重新分组后，之前绑定的养殖场将自动解绑，确认是否继续操作？",
    submitTip_9:"此字段为必填字段",
    submitTip_10:"导入失败，请确认数据再试",
    submitTip_11:"文件标题栏为空,解析失败",
    submitTip_12:"第一行名称错误",
    submitTip_13:"标题栏为空",
    submitTip_14:"获取名称为undefind",
    submitTip_15:"文件上传成功",
    submitTip_16:"文件格式错误，请重新导入",
    submitTip_17:"勾选中有已绑定值",
    submitTip_18:"请重新勾选",
    submitTip_19:"请勾选耳标",
    submitTip_20:"请选择企业或填写未选则企业原因",
    submitTip_21:"已添加导入文件",
    submitTip_22:"正在导入，请稍后...",
    submitTip_23:"填写未选则企业的原因",
};
export const labelnumber_en = {
    reason:"Reason",
    isBind:"Binding status",
    isBind_1:"Bound",
    isBind_0:"Unbound",
    tagType:"Ear tag type",
    tagNumber:"Ear tag number",
    enterTagNumber:"Please enter",
    other:"Other",
    tagStatus:"Low power alarm",
    lowPower:"Low power",
    normal:"Normal",
    time:"Registration time",
    addTag:"Add ear tag",
    batchTag:"Batch add",
    delBatchTag:"Batch delete",
    jdFarm:"JD Farm",
    registerTime:"Binding time",
    registerUserName:"Wearer",
    createTime:"Creation time",
    createUserName:"Creator",
    lowBatteryTime:"Warning time",
    dialogTitle:"Tag group (please select the tag for grouping)",
    dialogTitle_1:"One-click grouping",
    dialogTitle_2:"Import grouping",
    upload:"Upload",
    dialogTip:"If there is no import template, please download here",
    downloadModel:"Download template",
    optionshell:"Operation interface",
    custNo:"Customer number",
    custName:"Customer name",
    batchImport:"Batch import",
    yes:"Yes",
    no:"No",
    noFileTip:"Please upload the file",
    linkCompany:"Associated enterprise",
    enterTag:"Please enter the ear tag number",
    selectCompany:"Please select the enterprise",
    submitTip_1:"Unable to delete the bound ear tag number",
    submitTip_2:"This operation will delete the ear tag, do you want to continue?",
    submitTip_3:"prompt",
    submitTip_4:"Delete success",
    submitTip_5:"Please check the ear tag number before batch deletion",
    submitTip_6:"Added successfully",
    submitTip_7:"Please check the data before operation",
    submitTip_8:"The currently selected device is bound to the farm. After re-grouping, the previously bound farm will be automatically unbound. Are you sure to continue?",
    submitTip_9:"This field is required",
    submitTip_10:"Import failed, please confirm the data and try again",
    submitTip_11:"The file title bar is empty and the parsing fails",
    submitTip_12:"The first row name is wrong",
    submitTip_13:"The title bar is empty",
    submitTip_14:"The name is undefind",
    submitTip_15:"File upload success",
    submitTip_16:"File format error, please re-import",
    submitTip_17:"There are already bound values in the selected check",
    submitTip_18:"Please check again",
    submitTip_19:"Please check the ear tag",
    submitTip_20:"Please select a company or provide a reason for not selecting a company.",
    submitTip_21:"The import file has been added",
    submitTip_22:"Importing, please wait...",
    submitTip_23:"Enter the reason why the enterprise is not selected",
};