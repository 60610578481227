import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import i18n from '@/vue-il8n';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		isTimer: false,
		menusButton: '',
		resourceCodes: '',
		changeMenus: '',
		childrenList: '',
		addressId: '',
		addressName: '',
		newMapsAddress: [],
		tagsHeight: 0,
		asideWidth: 0,

		lanuage: '',
	},
	mutations: {
		setIsTimer(state) {
			state.isTimer = true;
		},
		setMenusButton(state, states) {
			state.menusButton = states;
		},
		setResourceCodes(state, states) {
			state.resourceCodes = states;
		},
		setChangeMenus(state, states) {
			console.log(states)
			state.changeMenus = states
		},
		setChildrenList(state, states) {
			console.log(states)
			state.childrenList = states
		},
		setAddressId(state, states) {
			console.log(state)
			state.addressId = states

			console.log(states.addressId)
		},
		setTagsHeight(state, states) {
			console.log(state)
			state.tagsHeight = states
		},
		setAsideWidth(state, states) {
			console.log(state)
			state.asideWidth = states
		},

		updateLanuage(state, lanuage) {
			console.log(lanuage +'执行ing')
			state.lanuage = lanuage;
			i18n.locale = lanuage;
		},
	},
	actions: {
		async updateLanuage({ commit }, lanuage) {
			commit('updateLanuage', lanuage);
		}
	},
	modules: {
	},
	plugins: [createPersistedState({
		key: 'lanuage',
		paths: ['lanuage'],
		storage: window.sessionStorage, // 将状态存储在会话存储中
	})]
});
