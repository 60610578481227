// 发情
export const estrus_zh = {
    search: {
        date: {
            title: "发情日期"
        },
    },
    table: {
        phenomenon: "现象描述",
        oestrusType: "发情类别",
        remark: "备注信息",
        oestrusType_1: "同期发情",
        oestrusType_2: "自然发情",
    }
}
export const estrus_en = {
    search: {
        date: {
            title: "Estrus date"
        },
    },
    table: {
        phenomenon: "Phenomenon Description",
        oestrusType: "Heat Type",
        remark: "Remark Information",
        oestrusType_1: "Synchronized Heat",
        oestrusType_2: "Natural Heat",
    }
}