export const farm_zh = {
    addFarm: "添加养殖户",
    insuranceCompanyName: "保险公司",
    person: "负责人",
    mobile: "联系电话",
    createTime: "创建/申请时间",
    createUserName: "创建/申请人",
    createType: "创建类型",
    createType_1: "后台添加",
    createType_2: "用户申请",
    auditStatus: "审核状态",
    auditTime: "审核时间",
    auditStatus_1: "审核通过",
    auditStatus_2: "审核拒绝",
    auditStatus_3: "待审核",
    status: "状态",
    status_1: "禁用",
    status_2: "启用",
    companyType: "企业类型",
    companyType_1: "标识类型",
    companyType_2: "自繁自养",
    companyType_3: "企业+农户",
    companyType_4: "政府+农户",
    feedingTypeDesc: "饲养方式",
    farmInfo: "养殖场信息",
    farmType: "养殖场类型",
    isInternational: "企业注册地",
    chinese: "中国",
    overseas: "海外",
    farmName: "养殖场名称",
    enterFarmName: "请输入养殖场名称",
    groupCode: "所属地址/组织机构",
    selectinsuranceCompanyName: "选择购买保险公司",
    notHave: "无",
    person_1: "负责人",
    mobile_1: "联系电话",
    detailAddress: "详细地址",
    doorUrl_title: "上传照片",
    doorUrl: "门口照片",
    licenseUrl: "营业执照",
    epidemicUrl: "动物防疫条件合格证",
    animalBusiness: "畜禽经营许可证",
    pic5: "农用地审批",
    pic6: "环评报告",
    pic7: "粪污处理环评验收报告",
    pic8: "环境照片1",
    pic9: "环境照片2",
    pic10: "环境照片3",
    pic11: "环境照片4",
    originDescribe_title: "产地说明",
    originDescribe_placeholder: "养殖场产地说明，字数在300字以内",
    status_info: "启用状态",
    tip_1: "请选择组织机构",
    tip_2: "请填写联系电话",
    tip_3: "请填写联系人",
    tip_4: "请选择启用状态",
    tip_5: "请填写养殖场名称",
    tip_6: "请输入2-20个汉字之间，不能包含特殊字符",
    tip_7: "请添加养殖类型",
    tip_8: "请填写详细地址",
    tip_9: "请上传照片",
    tip_10: "请选择企业",
    tip_11: "请选择饲养方式",
    tip_12: "最大字数为三百字",
    message_push: "是否启用消息推送",
    show_good_pasture: "是否在小程序优质牧场显示",
}
export const farm_en = {
    addFarm: "Add Farm",
    insuranceCompanyName: "Insurance Company",
    person: "Contact",
    mobile: "Mobile",
    createTime: "Create/Apply Time",
    createUserName: "Create/Apply User",
    createType: "Create Type",
    createType_1: "Background Add",
    createType_2: "User Apply",
    auditStatus: "Audit Status",
    auditTime: "Audit Time",
    auditStatus_1: "Audit Pass",
    auditStatus_2: "Audit Reject",
    auditStatus_3: "Pending Audit",
    status: "Status",
    status_1: "Disable",
    status_2: "Enable",
    companyType: "Company Type",
    companyType_1: "Identification Type",
    companyType_2: "Self-bred and self-raised",
    companyType_3: "Enterprise + Farmer",
    companyType_4: "Government + Farmer",
    feedingTypeDesc: "Feeding Type",
    farmInfo: "Farm Information",
    farmType: "Farm Type",
    isInternational: "Enterprise Registration Place",
    chinese: "China",
    overseas: "Overseas",
    farmName: "Farm Name",
    enterFarmName: "Enter Farm Name",
    groupCode: "Address/Organization",
    selectinsuranceCompanyName: "Select insurance company",
    notHave: "Not Have",
    person_1: "Person in charge",
    mobile_1: "Contact number",
    detailAddress: "Detailed address",
    doorUrl_title: "Upload photo",
    doorUrl: "Door photo",
    licenseUrl: "Business license",
    epidemicUrl: "Animal epidemic prevention qualification certificate",
    animalBusiness: "Animal and poultry business license",
    pic5: "Agricultural land approval",
    pic6: "Environmental impact assessment report",
    pic7: "Environmental impact assessment acceptance report for manure treatment",
    pic8: "Environmental photo 1",
    pic9: "Environmental photo 2",
    pic10: "Environmental photo 3",
    pic11: "Environmental photo 4",
    originDescribe_title: "Origin Description",
    originDescribe_placeholder: "Description of the origin of the farm, within 300 words",
    status_info: "Enable status",
    tip_1: "Please select the organization",
    tip_2: "Please fill in the contact number",
    tip_3: "Please fill in the contact person",
    tip_4: "Please select the enable status",
    tip_5: "Please fill in the farm name",
    tip_6: "Please enter between 2-20 Chinese characters, without special characters",
    tip_7: "Please add breeding type",
    tip_8: "Please fill in the detailed address",
    tip_9: "Please upload photos",
    tip_10: "Please select the enterprise",
    tip_11: "Please select the feeding method",
    tip_12: "The maximum number of words is three hundred",
}