export const screen_config_zh = {
    firmScreen_option: "畜牧养殖数智化管理平台-预警开关设置",
    insuranceScreen_option: "保险畜牧养殖数据平台-预警开关设置",
    bankScreen_option: "活体抵押贷款数据平台-预警开关设置",
    open: "开",
    close: '关'
}
export const screen_config_en = {
    firmScreen_option: "Digital Dashboard warning setting",
    insuranceScreen_option: "Insurance  Dashboard warning setting",
    bankScreen_option: "Bank Dashboard warning setting",
    open: "On",
    close: 'Off'
}