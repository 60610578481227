import Vue from 'vue';
import VueI18n from "vue-i18n"

// vxe-table
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import enUS from 'vxe-table/lib/locale/lang/en-US'

//element-ui
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
Vue.use(VueI18n)

//login
import { login_zh, login_en } from '@/vue-il8n/login_module/index'

// 菜单
import { menu_zh, menu_en } from '@/vue-il8n/menu'
//htpp
import { http_zh, http_en } from '@/vue-il8n/http'

// 通用模块
import { common_zh, common_en } from '@/vue-il8n/common_module/index'

//home
import { home_zh, home_en } from '@/vue-il8n/home_module/index'

// 养殖模块
/**养殖企业决策看板 */
import { kanban_card_zh, kanban_card_en } from '@/vue-il8n/breeding_module/kanban_card'
/**免疫登记信息 */
import { immunization_zh, immunization_en } from '@/vue-il8n/breeding_module/Immunization'
/**治疗登记信息 */
import { cure_zh, cure_en } from '@/vue-il8n/breeding_module/cure'
/**发情登记信息 */
import { estrus_zh, estrus_en } from '@/vue-il8n/breeding_module/estrus'
/**配种 */
import { breed_zh, breed_en } from '@/vue-il8n/breeding_module/breed'
/**孕检 */
import { test_zh, test_en } from '@/vue-il8n/breeding_module/pregnancy_test'
/**分娩 */
import { childbirth_zh, childbirth_en } from '@/vue-il8n/breeding_module/childbirth'
/**死亡 */
import { die_zh, die_en } from '@/vue-il8n/breeding_module/die'
/**电子档案 */
import { archives_zh, archives_en } from '@/vue-il8n/breeding_module/archives'
/**大屏 */
import { screen_zh, screen_en } from '@/vue-il8n/breeding_module/screen'


// ai
/**健康预警 */
import { health_zh, health_en } from '@/vue-il8n/ai_modeule/health'
/**发情 */
import { oestrus_zh, oestrus_en } from '@/vue-il8n/ai_modeule/oestrus'
/**死亡 */
import { ai_die_zh, ai_die_en } from '@/vue-il8n/ai_modeule/die'
/**离开 */
import { leave_zh, leave_en } from '@/vue-il8n/ai_modeule/leave'
/**耳标异常 */
import { lba_zh, lba_en } from '@/vue-il8n/ai_modeule/labelNumberAbnormal'

//基础信息
/**查看耳标 */
import { label_info_zh, label_info_en } from '@/vue-il8n/basic_module/labelNumber_info'
/**温度运动 */
import { temperature_sport_zh, temperature_sport_en } from '@/vue-il8n/basic_module/temperatureSport'

// system_module
import { farm_zh, farm_en } from '@/vue-il8n/system_module/farm'
import { web_user_zh, web_user_en } from '@/vue-il8n/system_module/web_user'
import { app_user_zh, app_user_en } from '@/vue-il8n/system_module/app_user'

//硬件模块
import { labelnumber_zh, labelnumber_en } from '@/vue-il8n/hardware_module/labelnumber'

//config_module
import { screen_config_zh, screen_config_en } from '@/vue-il8n/config_module/bigscreen_warning/index'
import { beef_config_zh, beef_config_en } from '@/vue-il8n/config_module/beef_config/index'


const messages = {
    zh: {
        ...zhCN,
        ...zhLocale,
        login: login_zh,
        menu: menu_zh,
        http: http_zh,
        home: home_zh,
        common: {
            ...common_zh
        },
        breeding_module: {
            kanban_card: kanban_card_zh,//养殖企业决策看板
            Immunization: immunization_zh,//免疫登记信息
            cure: cure_zh,//治疗登记信息
            estrus: estrus_zh,//发情
            breed: breed_zh,//配种
            test: test_zh,//孕检
            child: childbirth_zh,//分娩
            die: die_zh,//死亡
            archives: archives_zh,
            screen: screen_zh,
        },
        ai_module: {
            health: health_zh,
            oestrus: oestrus_zh,
            die: ai_die_zh,
            leave: leave_zh,
            lba: lba_zh,
        },
        basic_module: {
            label_info: label_info_zh,
            ts: temperature_sport_zh,
        },
        system: {
            farm: farm_zh,
            web_user: web_user_zh,
            app_user: app_user_zh,
        },
        config: {
            screen_config: screen_config_zh,
            beef_config:beef_config_zh,
        },
        hardware_module: {
            labelnumber: labelnumber_zh
        }
    },
    en: {
        ...enUS,
        ...enLocale,
        login: login_en,
        menu: menu_en,
        http: http_en,
        home: home_en,
        common: {//通用模块
            ...common_en
        },
        breeding_module: {
            kanban_card: kanban_card_en,//养殖企业决策看板
            Immunization: immunization_en,//免疫登记信息
            cure: cure_en,//治疗登记信息
            estrus: estrus_en,//发情
            breed: breed_en,//配种
            test: test_en,//孕检
            child: childbirth_en,//分娩
            die: die_en,//死亡
            archives: archives_en,
            screen: screen_en,
        },
        ai_module: {
            health: health_en,
            oestrus: oestrus_en,
            die: ai_die_en,
            leave: leave_en,
            lba: lba_en,
        },
        basic_module: {
            label_info: label_info_en,
            ts: temperature_sport_en,
        },
        system: {
            farm: farm_en,
            web_user: web_user_en,
            app_user: app_user_en,
        },

        config: {
            screen_config: screen_config_en,
            beef_config:beef_config_en,
        },
        hardware_module: {
            labelnumber: labelnumber_en
        }
    },

}

const i18n = new VueI18n({
    locale: 'en', // 设置默认语言
    messages, // 设置语言包
});

export default i18n