import { AxiosResponse } from 'axios';
import Vue from 'vue';
import { Column, Result } from '@/common/Views/system/column/column';
import { DeepLoop } from '@/common/deepLoop/deepLoop';

export interface StartAndEndTime {
    startTime: string;
    endTime: string;
}

export class Util {
    public clearObject<T>(arg: T): T {
        const newObject: any = {};
        for (const key in arg) {
            if (Object.prototype.toString.call(arg[key]) === '[object Array]') {
                newObject[key] = [];
            } else {
                newObject[key] = '';
            }
        }

        return newObject;
    }

    public getId(id: string | undefined | null): string {
        return id ? id : '';
    }

    public static dateUtil(date: string | undefined | Date): string {
        if (!date) {
            return '';
        }
        const getDate = new Date(date);

        return `${getDate.getFullYear()}-${this.fillZero(getDate.getMonth() + 1)}-${this.fillZero(getDate.getDate())}`;
    }
    public static getCurrentTime(){
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        console.log(date,'date')
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    public static getStartCurrentTime() {
        const date = new Date();
        date.setMinutes(date.getMinutes() - 10); // 将时间往前推10分钟
    
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
    
        console.log(date, 'date');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    

    public static fillZero(count: number | string): string {
        return count < 10 ? '0' + count : String(count);
    }

    public static dateTimeUtil(dateTime: string | undefined | Date): string {
        if (!dateTime) {
            return '';
        }
        const date = this.dateUtil(dateTime);
        const getDate = new Date(dateTime);
        return `${date} ${this.fillZero(getDate.getHours())}:${this.fillZero(getDate.getMinutes())}:${this.fillZero(getDate.getSeconds())}`;
    }

    public static dateTimeUtilnohms(dateTime: string | undefined | Date): string {
        if (!dateTime) {
            return '';
        }
        const date = this.dateUtil(dateTime);
        console.log(date)
        // const getDate = new Date(dateTime);
        return `${date}`;
    }

    public static getHoursAndMinutes(date: Date): string {
        const time = new Date(date);
        return `${this.fillZero(time.getHours())}:${this.fillZero(time.getMinutes())}`;
    }


    public getUploading(blob: Blob, res: AxiosResponse<Blob> | string): void {
        const data = new Blob([blob], { type: 'application/vnd.ms-excel;charset=UTF-8' });
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        let fileName;

        if (typeof res === 'string') {

            fileName = res;

        } else {

            fileName = res.headers['content-disposition'].split('filename=')[1].split('.')[1];

        }


        link.setAttribute('download', `${new Date().getTime()}.${fileName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public assignObject(form1: any, form2: any) {
        let form: any = form1;
        let otherForm: any = form2;
        for (const i in form) {
            form[i] = otherForm[i];
        }
    }


    public getSexNumber(sex: string): string {
        let result: string = sex;
        if (sex === '男' || sex === '女') {
            if (sex === '男') {
                result = '1';
            } else {
                result = '0';
            }
        }

        return result;
    }

    public static remToPx(rem: number): number {
        const fontSize: number = parseInt(<string>document.documentElement.style.fontSize);
        return fontSize * rem;
    }

    public static showToast(msg: string, type: string = 'yes') {
        if (!sessionStorage.getItem('showToast')) {
            sessionStorage.setItem('showToast', '123');
            const vue = new Vue();
            if (type === 'yes') {

                vue.$message.success(msg);
                console.log(msg);
            } else if (type === 'warning') {
                vue.$message.warning(msg);
            } else {
                vue.$message.error(msg);
            }

            setTimeout(() => {
                sessionStorage.removeItem('showToast');
            }, 3000);
        }
    }

    public static handleStartTimeAndEndTime(time: Date[], hours: boolean = true): StartAndEndTime {
        if (!time) {
            return {
                startTime: '',
                endTime: '',
            };
        }
        return hours ? {
            startTime: this.dateTimeUtil(time[0]),
            endTime: this.dateTimeUtil(time[1])
        } : { startTime: this.dateUtil(time[0]), endTime: this.dateUtil(time[1]) };

    }

    public static handleStartTimeAndEndTimevideo(time: Date[], hours: boolean = true): any {
        if (!time) {
            return {
                beginTime: '',
                endTime: '',
            };
        }
        return hours ? {
            beginTime: this.dateTimeUtil(time[0]),
            endTime: this.dateTimeUtil(time[1])
        } : { beginTime: this.dateUtil(time[0]), endTime: this.dateUtil(time[1]) };

    }

    public static handleStartTimeAndEndTimenohms(time: Date[], hours: boolean = true): StartAndEndTime {
        if (!time) {
            return {
                startTime: '',
                endTime: '',
            };
        }
        return hours ? {
            startTime: this.dateTimeUtilnohms(time[0]),
            endTime: this.dateTimeUtilnohms(time[1])
        } : { startTime: this.dateUtil(time[0]), endTime: this.dateUtil(time[1]) };

    }
    public static auditTime(time: Date[], hours: boolean = true): StartAndEndTime {
        if (!time) {
            return {
                auditTimeStart: '',
                auditTimeEnd: '',
            };
        }
        return hours ? {
            auditTimeStart: this.dateTimeUtil(time[0]),
            auditTimeEnd: this.dateTimeUtil(time[1])
        } : { auditTimeStart: this.dateUtil(time[0]), auditTimeEnd: this.dateUtil(time[1]) };

    }


    public static handleStartTimeAndEndTime1(time: Date[], hours: boolean = true): StartAndEndTime {
        if (!time) {
            return {
                insureStart: '',
                insureEnd: '',
            };
        }
        return hours ? {
            insureStart: this.dateTimeUtil(time[0]),
            insureEnd: this.dateTimeUtil(time[1])
        } : { insureStart: this.dateUtil(time[0]), insureEnd: this.dateUtil(time[1]) };

    }
    public static handleStartTimeAndEndTime2(time: Date[], hours: boolean = true): StartAndEndTime {
        if (!time) {
            return {
                effectStart: '',
                effectEnd: '',
            };
        }
        return hours ? {
            effectStart: this.dateTimeUtil(time[0]),
            effectEnd: this.dateTimeUtil(time[1])
        } : { effectStart: this.dateUtil(time[0]), effectEnd: this.dateUtil(time[1]) };

    }
    public static handleStartTimeAndEndTime3(time: Date[], hours: boolean = true): StartAndEndTime {
        if (!time) {
            return {
                inEffectStart: '',
                inEffectEnd: '',
            };
        }
        return hours ? {
            inEffectStart: this.dateTimeUtil(time[0]),
            inEffectEnd: this.dateTimeUtil(time[1])
        } : { inEffectStart: this.dateUtil(time[0]), inEffectEnd: this.dateUtil(time[1]) };

    }

    public static getTree(cb?: Function): void {

        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {
        column.getTreeData('/animal/web/provinceCityDistrict/getForGroupCode').then((result: Result[]) => {

            const data = new DeepLoop().deepCopy(result);

            if (cb) {
                cb(data);
            }
        }).catch(err => {
            new Vue().$message.error(err.message);
        });
    }


    public static getTree3(cb?: Function): void {

        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {
        column.getTreeData('/animal/web/provinceCityDistrict/getForGroupCode').then((result: Result[]) => {

            const data = new DeepLoop().deepCopy(result);

            if (cb) {
                cb(data);
            }
        }).catch(err => {
            new Vue().$message.error(err.message);
        });
    }

    public static getTree2(cb?: Function): void {
        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {
        column.getTreeData('/animal/web/provinceCityDistrict/getForGroupCode').then((result: Result[]) => {

            const data = new DeepLoop().deepCopy(result);

            if (cb) {
                cb(data);
            }
        }).catch(err => {
            new Vue().$message.error(err.message);
        });
    }

    public static getTrees(cb?: Function): void {
        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {
        column.getTreeData('/animal/web/thirdMonitor/groupCode').then((result: Result[]) => {

            const data = new DeepLoop().deepCopy(result);

            if (cb) {
                cb(data);
            }
        }).catch(err => {
            new Vue().$message.error(err.message);
        });
    }

    public static animation(dom: HTMLElement) {
        requestAnimationFrame(() => {
            if ((dom.scrollHeight - dom.scrollTop) === dom.clientHeight) {
                dom.scrollTop = 0;
            }
            // if (dom.scrollTop === 0) {
            //     flag = false;
            // }
            dom.scrollTop += 1;

            this.animation(dom);
        });
    }


    public static getDay(day: number) {
        const today = new Date();

        const targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

        today.setTime(targetday_milliseconds); //注意，这行是关键代码

        const tYear = today.getFullYear();
        let tMonth: string | number = today.getMonth();
        let tDate: string | number = today.getDate();
        tMonth = Util.fillZero(tMonth + 1);
        tDate = Util.fillZero(tDate);
        return tYear + '-' + tMonth + '-' + tDate;
    }

    public static fmoney(s: string, n: number) {
        n = n >= 0 && n <= 20 ? n : 2;
        if (n === 0) {
            s = parseFloat((s + '').replace(/[^\d\.-]/g, '')) + '';
        } else {
            s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
        }
        let l = s.split('.')[0].split('').reverse();
        let r = n === 0 ? '' : s.split('.')[1];
        let t: string = '';
        for (let i = 0; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '');
        }
        return n === 0 ? t.split('').reverse().join('') : t.split('').reverse().join('') + '.' + r;
    }
    public static fmoney2(s: string) {
        // if (s === '0') {
        //     return  '0';
        // }

        const wan = s.length - 4 <= 0 ? '' : s.slice(0, s.length - 4) + '万';
        return wan + s.slice(s.length - 4, s.length) + '元';
    }
}
