import { BasicsClass } from '@/common/BasicsClass';

export class customTitle extends BasicsClass {
  public homeTitle(cb: Function) {
    this.BasicGet('/animal/web/homeTitle/get', {}, false, true, cb, cb);
  }
  public saveOrMerge(form: any, cb: Function) {
    console.log(form);
    
    this.BasicPost('/animal/web/homeTitle/saveOrMerge', {
      ...form
    }, true, true, true, cb);
  }
}
