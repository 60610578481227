// 大屏温度
export const screen_zh = {
  back:'返回',
  Enterprise_digital_breeding_management_platform:'企业数字化养殖管理平台',
  Variety_category_selection:'品种类别选择',
  Health_warning:'健康预警',
  Leave_illegally:'非法离开',
  Determination_of_death:'死亡判别',
  close:'关闭',
  A_total_of:'共',
  has_not_been_counted:'个耳标未盘点到，请悉知',
  Ear_tag_off:'耳标脱落',
  Illegal_departure:'非法离开',
  Low_battery:'低电量',
  other:'其他',
  count:'盘点数',
  Dial_number:'戴标数',
  Device_offline_state:'设备离线状态',
  available:'暂无在线设备',
  Video_surveillance:'视频监控',
  Previous:'上一页',
  Next_page:'下一页',
  Piece_of_data:'条数据',
  Number_of_fields:'存栏数量',
  temperature:'温度',
  humidness:'湿度',
  Device_offline:'设备不在线',
  No_data_available:'暂无数据',
  No_equipment:'暂无设备',
  Current_month:'本月',
  This_week:'本周',
  The_same_day:'当天',
  Quantity_of_livestock_sold:'牲畜销售数量',
  Number_of_livestock_transfers:'牲畜调运数量',
  Total_milk_production:'产奶总量',
  Average_milk_production:'平均产奶量',
  Tons_of:'吨',
  Kgt:'公斤/头/天',
  Milk_production_statistics:'产奶量统计',
  count_quantity:'实时盘点数量',
  Video_supervision:'视频监管',
  health_warnings:'健康预警数量',
  Illegal_departures:'非法离开数量',
  death:'AI死亡判别',
  Real_time:'实时盘点',
  Failure:'未能盘点',
  head:'头',
  Monitor_the_farm:'监管养殖场：',
  Total_supervision:'监管总数：',
  a:'个',
  No_more_videos:"无更多视频",
  Eqpt:"设备编号",
}
export const screen_en = {
  back:'back',
  Enterprise_digital_breeding_management_platform:'Digital Dashboard',
  Variety_category_selection:'Variety',
  Health_warning:'Health warning',
  Leave_illegally:'Leave illegally',
  Determination_of_death:'Determination of death',
  close:'close',
  A_total_of:'A total of',
  has_not_been_counted:'Please note that one ear tag has not been counted',
  Ear_tag_off:'Ear tag off',
  Illegal_departure:'Illegal departure',
  Low_battery:'Low battery',
  other:'other',
  count:'count',
  Dial_number:'Dial number',
  Device_offline_state:'Device offline state',
  available:'No online device is available',
  Video_surveillance:'Video surveillance',
  Previous:'Previous',
  Next_page:'Next page',
  Piece_of_data:'Piece of data',
  Number_of_fields:'Inventory',
  temperature:'temperature',
  humidness:'humidness',
  Device_offline:'Device offline',
  No_data_available:'No data available',
  No_equipment:'No equipment',
  Current_month:'month',
  This_week:'week',
  The_same_day:'day',
  Quantity_of_livestock_sold:'sell',
  Number_of_livestock_transfers:'transport',
  Total_milk_production:'Total milk production',
  Average_milk_production:'Average milk production',
  Tons_of:'Tons of',
  Kgt:'Kg/head/day',
  Milk_production_statistics:'Milk production statistics',
  count_quantity:'Real-time count quantity',
  Video_supervision:'Video supervision',
  health_warnings:'Number of health warnings',
  Illegal_departures:'Illegal departures',
  death:'AI death discrimination',
  Real_time:'Real time',
  Failure:'Failure',
  head:'head',
  Monitor_the_farm:'Monitor the farm:',
  Total_supervision:'Total Monitor',
  a:'a',
  No_more_videos:"No more videos",
  Eqpt:"Eqpt No",
}