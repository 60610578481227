import Vue from 'vue';
import Qs from 'qs';
import router from '@/router';
import { noHeader } from '@/common/http/noHeader';
import { AxiosError, AxiosResponse } from 'axios';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { HandleTable } from '@/common/Views/comm/handleTable';
import includes from 'xe-utils/methods';
import { Util } from '@/common/Views/comm/util';
import { overdueCode } from '@/common/http/overdueCode';
import { Login } from '@/common/login/login';
import i18n from '@/vue-il8n';
import store from '@/store';

// interface Result {
//     code: number;
//     data: object;
//     message: string;
// }

type PromiseInt<T> = (a: T) => void;

interface AxiosErrorMsg {
    message: string;
}

export interface AxiosData<T> {
    data: T;
    message: string;
    code: number;
    page: HandleTable;
}

export interface Header {
    [more: string]: string;
}

export class Axios {
    private vue: Vue;
    private CancelToken: any;
    private loading: ElLoadingComponent | undefined;
    public headers: Header = {};

    constructor() {
        this.vue = new Vue();
    }

    public get<T>(url: string, data: any, setLoading: boolean = false): Promise<AxiosData<T>> {
        this.handleHttpAndHeader(url);


        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }
        data.mchId = localStorage.getItem('selectMchId');
        if (!data.platform) {
            data.platform = 1;
        }

        return new Promise((resolve, reject) => {
            this.vue.$axios.get(url, {
                params: this.clearParamsNull(data),
                headers: this.headers,
                cancelToken: this.CancelToken.token,
            }).then((result: AxiosResponse<AxiosData<T>>) => {
                this.cleanLoading(timer);
                this.setResult(result, resolve, reject);

            }).catch((err: AxiosErrorMsg | AxiosError) => {

                this.catchError(timer, err);

            });
        });
    }

    public get1<T>(url: string, data: any, setLoading: boolean = false): Promise<AxiosData<T>> {
        this.handleHttpAndHeader(url);


        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }
        data.mchId = localStorage.getItem('selectMchId');
        if (!data.platform) {
            data.platform = 1;
        }

        return new Promise((resolve, reject) => {
            this.vue.$axios.get(url, {
                params: data,
                headers: this.headers,
                cancelToken: this.CancelToken.token,
            }).then((result: AxiosResponse<AxiosData<T>>) => {
                this.cleanLoading(timer);
                this.setResult(result, resolve, reject);

            }).catch((err: AxiosErrorMsg | AxiosError) => {

                this.catchError(timer, err);

            });
        });
    }

    public fileWithGet<T>(url: string, data: any, setLoading: boolean = false) {
        this.handleHttpAndHeader(url);
        let timer: number = 0;
        const urlList = ["/animal/web/weight/export"]
        if (setLoading) {
            timer = this.setLoading();
        }
        data.mchId = localStorage.getItem('selectMchId');
        this.vue.$axios.get(
            url,
            {
                params: urlList.includes(url) ? data : this.clearParamsNull(data),
                headers: this.headers,
                responseType: 'blob',
                cancelToken: this.CancelToken.token,
            }).then((res: AxiosResponse<Blob>) => {

                // clearTimeout(timer);
                // this.cleanLoading(timer);
                // new Util().getUploading(res.data, res);

                this.cleanLoading(timer);

                // if ((res as AxiosResponse<Blob>).data.type === 'application/json') {
                //     const reader = new FileReader();
                //     reader.onload = (event: Event) => {
                //         console.log(event.target.result)
                //         const result = JSON.parse((<any> event.target!).result);
                //         // const result = (<any>event.target!).result;
                //         Util.showToast(result.message, 'no');
                //     };

                //     reader.readAsText(res.data);
                //     return;
                // }

                new Util().getUploading((<AxiosResponse<Blob>>res).data, <AxiosResponse<Blob>>res);

            }).catch((err: AxiosError | AxiosErrorMsg) => {

                this.catchError(timer, err);

            });
    }

    public fileImport<T>(url: string, data: FormData, callBack?: Function, setLoading: boolean = false) {
        this.handleHttpAndHeader(url);
        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }
        this.headers['Content-Type'] = 'multipart/form-data';

        this.vue.$axios.post(url,
            data,
            {
                headers: this.headers,
                cancelToken: this.CancelToken.token,
            }).then((res: AxiosResponse<AxiosData<T>>) => {

                this.cleanLoading(timer);

                if (callBack) {
                    callBack(res.data);
                }

            }).catch((err: AxiosErrorMsg | AxiosError) => {

                this.catchError(timer, err);

            });
    }

    public post<T>(url: string, data: any, json: boolean = false, setLoading: boolean = false): Promise<AxiosData<T>> {
        this.handleHttpAndHeader(url);

        if (!data.platform) {
            data.platform = 1;
        }

        data.mchId = localStorage.getItem('selectMchId');
        let params: string | object;
        params = this.clearParamsNull(data);
        if (!json) {
            params = Qs.stringify(params);
        }

        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }

        return new Promise((resolve, reject) => {
            this.vue.$axios.post(
                url,
                params,
                {
                    headers: this.headers,
                    cancelToken: this.CancelToken.token,
                },
            ).then((result: AxiosResponse<AxiosData<T>>) => {

                this.cleanLoading(timer);

                this.setResult(result, resolve, reject);

            }).catch((err: AxiosErrorMsg | AxiosError) => {
                // 重复请求
                if (err.message !== i18n.t('http.repet')) {
                    reject(err);
                }
                this.catchError(timer, err);

            });
        });
    }

    public post1<T>(url: string, data: any, json: boolean = false, setLoading: boolean = false): Promise<AxiosData<T>> {
        this.handleHttpAndHeader(url);

        if (!data.platform) {
            data.platform = 1;
        }

        data.mchId = localStorage.getItem('selectMchId');
        let params: string | object;
        params = data
        if (!json) {
            params = Qs.stringify(params);
        }

        let timer: number = 0;
        if (setLoading) {
            timer = this.setLoading();
        }

        return new Promise((resolve, reject) => {
            this.vue.$axios.post(
                url,
                params,
                {
                    headers: this.headers,
                    cancelToken: this.CancelToken.token,
                },
            ).then((result: AxiosResponse<AxiosData<T>>) => {

                this.cleanLoading(timer);

                this.setResult(result, resolve, reject);

            }).catch((err: AxiosErrorMsg | AxiosError) => {
                if (err.message !== i18n.t('http.repet')) {
                    reject(err);
                }
                this.catchError(timer, err);

            });
        });
    }

    private setResult<T>(result: AxiosResponse<AxiosData<T>>, resolve: PromiseInt<AxiosData<T>>, reject: PromiseInt<AxiosData<T>>): void {

        if (result.data.code === 200) {

            resolve(result.data);

        } else if (overdueCode.includes(result.data.code)) {

            reject(result.data);
            setTimeout(() => {
                localStorage.clear();
                router.push('/login');

            }, 500);

        } else if (result.data.code === 401) {
            reject(result.data);
            // this.cleanLoading(timer)
            // new Login().logout();
            // router.push('/login');
        } else {
            reject(result.data);
        }
    }

    private cancelAxios(): void {
        try {
            this.CancelToken.cancel(i18n.t('http.repet'));
        } catch {
            //    do nothing
        }
    }

    private setLoading(): number {
        const timer = setTimeout(() => {
            this.loading = this.vue.$loading({
                lock: true,
                text: `${i18n.t('http.loading')}...`,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        }, 1000);

        return timer;
    }


    private cleanLoading(timer: number): void {

        clearTimeout(timer);

        try {
            this.loading!.close();

        } catch {
            //    do nothing
        }
    }

    public clearParamsNull<T>(data: any) {
        let resultParams: any = {};
        for (const i in data) {
            if (data[i] || data[i] === 0 || data[i] === false) {
                resultParams[i] = data[i];
            }

        }

        return resultParams;
    }

    private handleHttpAndHeader(url: string): void {
        this.cancelAxios();
        this.CancelToken = this.vue.$axios.CancelToken.source();
        // this.headers = noHeader.includes(url) ? this.headers :
        //     {Authorization: localStorage.getItem('token_type')! + localStorage.getItem('access_token') !};

        //设置语言请求头
        /**
         * 英 en 中 zh
         * 请求头接受语言头部
         * 先判断是否能够取到 localStorage.getItem('language')
         * 如果能够取到  使用 localStorage.getItem('language') 的值
         * 如果不能取到  使用默认语言 zh
         */
        this.headers['Accept-Language'] = store.state.lanuage
        // console.log(store)
        // end

        if (noHeader.includes(url)) {
            delete this.headers['Authorization'];
        } else {
            this.headers['Authorization'] = localStorage.getItem('token_type')! + localStorage.getItem('access_token')!;
        }

    }

    private catchError(timer: number, err: AxiosErrorMsg | AxiosError): void {
        this.cleanLoading(timer);

        if ((err as AxiosError).response && (<AxiosErrorMsg>err).message !== i18n.t('http.repet')) {


            if ((<AxiosError>err).response!.status === 500) {
                this.vue.$message.error(i18n.t('http.err_1') as string);
            } else {
                this.vue.$message.error((err as AxiosError).response!.statusText);
            }

        }

    }

}
