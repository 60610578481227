export const home_zh = {    
    tag_option:"标签选项",
    close_other:"关闭其他",
    close_all:"关闭所有",
}

export const home_en = {
    tag_option:"Tag Option",
    close_other:"Close Other",
    close_all:"Close All",
}