export const ai_die_zh = {
    dealFlag: "处理状态",
    dealFlag_0: "待处理",
    dealFlag_1: "已处理",
    createTime: "预警时间",
    batch_ignore: "批量忽略",
    estimatedWeight: "AI预估体重",
    deathReason: "死亡原因",
    isDeath: "处理方式",
    isDeath_0: "未死亡",
    isDeath_1: "已死亡",
    dealUserName: "处理人",
    dealTime: "处理时间",
    place_1: "请选择需要忽略的数据",
    place_2: "确定要批量忽略选中的死亡判别数据吗？",
    place_3: "提示",
    place_4: "忽略成功",
    g: "斤",
}
export const ai_die_en = {
    dealFlag: "Deal Status",
    dealFlag_0: "Pending",
    dealFlag_1: "Handled",
    createTime: "Warning Time",
    batch_ignore: "Batch Ignore",
    estimatedWeight: "AI Estimated Weight",
    deathReason: "Death Reason",
    isDeath: "Deal Type",
    isDeath_0: "Alive",
    isDeath_1: "Deceased",
    dealUserName: "Dealer",
    dealTime: "Deal Time",
    place_1: "Please select the data to ignore",
    place_2: "Are you sure you want to batch ignore the selected death judgment data?",
    place_3: "Prompt",
    place_4: "Ignore Success",
    g: "jin",
};