export const lba_zh = {
    updateTime: "预警时间",
    deviceType: "设备类型",
    deviceType_0: "基站",
    deviceType_1: "设备",
    dealFlag: "处理状态",
    dealFlag_0: "未处理",
    dealFlag_1: "已处理",
    labelNumber: "预警耳标号",
    status: "设备异常原因",
    status_1: "未读取到数据",
    status_2: "耳标损坏",
    status_3: "未佩戴",
    status_4: "耳标脱落",
    batch_ignore: "批量忽略",
    newLabelnum: "替换耳标编号",
    dealWay: "处理方式",
    dealUserName: "处理人",
    dealTime: "处理时间",
    dealFlagMsg: "处理状态",
    dealFlagMsg_0: "未处理",
    dealFlagMsg_1: "已处理",
    place_1: "请选择需要忽略的数据",
    place_2: "确定要批量忽略选中的耳标异常数据吗？",
    place_3: "提示",
    place_4: "忽略成功",
}
export const lba_en = {
    updateTime: "Warning Time",
    deviceType: "Device Type",
    deviceType_0: "Base Station",
    deviceType_1: "Equipment",
    dealFlag: "Deal Status",
    dealFlag_0: "Unhandled",
    dealFlag_1: "Handled",
    labelNumber: "Ear Tag ID",
    status: "Abnormal Reason",
    status_1: "No data read",
    status_2: "Ear Tag Damaged",
    status_3: "Not Worn",
    status_4: "Ear Tag Fallen Off",
    batch_ignore: "Batch Ignore",
    newLabelnum: "Replacement Ear Tag Number",
    dealWay: "Deal Way",
    dealUserName: "Dealer",
    dealTime: "Deal Time",
    dealFlagMsg: "Status",
    dealFlagMsg_0: "Unhandled",
    dealFlagMsg_1: "Handled",
    place_1: "Please select the data to ignore",
    place_2: "Are you sure you want to batch ignore the selected ear tag abnormal data?",
    place_3: "Prompt",
    place_4: "Ignore Success",
};