export const leave_zh = {
    dealFlag: "处理状态",
    dealFlag_0: "未处理",
    dealFlag_1: "已处理",
    createTime: "预警时间",
    batch_ignore: "批量忽略",
    warnDesc: "预警原因",
    ignore: "忽略",
    dispose: "处理",
    view: "查看",
    place_1: "请选择需要忽略的数据",
    place_2: "确定要批量忽略选中的非法离栏预警数据吗？",
    place_3: "提示",
    place_4: "忽略成功",
    age: "当前日龄",
    columnName: "养殖舍栏",
    capture: "事件截图",
    none_tip: "暂无图片",
    video: "录像视频",
    typeInfoName: "品种类型",
    day_unit: "天",
    houseName: "养殖舍（栋）",
    result: "选择处理结果",
    result_1: "被盗",
    result_2: "走丢",
    result_3: "其他",
    submit: "提交",
    place_5: "请选择处理结果",
    dealUserName:"处理人",
    dealTime:"处理时间",
}
export const leave_en = {
    dealFlag: "Deal Status",
    dealFlag_0: "Unhandled",
    dealFlag_1: "Handled",
    createTime: "Warning Time",
    batch_ignore: "Batch Ignore",
    warnDesc: "Warning Reason",
    ignore: "Ignore",
    dispose: "Dispose",
    view: "View",
    place_1: "Please select the data to ignore",
    place_2: "Are you sure you want to batch ignore the selected illegal pen exit warning data?",
    place_3: "Prompt",
    place_4: "Ignore Success",
    age: "Current Day Age",
    columnName: "Breeding Pen Name",
    capture: "Event Screenshot",
    none_tip: "No Image",
    video: "Video Recording",
    typeInfoName: "Breed Type",
    day_unit: "Day",
    houseName: "Breeding House (Building)",
    result: "Select Deal Result",
    result_1: "Stolen",
    result_2: "Lost",
    result_3: "Other",
    submit: "Submit",
    place_5: "Please select the deal result",
    dealUserName:"Handler",
    dealTime:"Handle Time",
};