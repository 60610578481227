// 治疗
export const cure_zh = {
    search: {
        date: {
            title: "治疗日期"
        },
    },
    table: {
        treatmentDate: "治疗日期",
        medicationName: "药品名称",
        dosage: "药量",
        manufacturer: "药品生产厂家",
        diseaseName: "疾病名称",
        symptom: "症状",
        sceneOperator: "兽医"
    }
}
export const cure_en = {
    search: {
        date: {
            title: "Treatment date"
        },
    },
    table: {
        treatmentDate: "Treatment date",
        medicationName: "Drug name",
        dosage: "dosage",
        manufacturer: "Drug manufacturers",
        diseaseName: "Disease name",
        symptom: "symptom",
        sceneOperator: "veterinary"
    }
}