export const beef_config_zh = {
    add:'新增预警配置',
    Breeding_warning:'配种预警',
    Pregnancy_warning:'孕检预警',
    Parturition_warning:'分娩预警',
    Creat_time:'创建时间',
    beefOne_one:'后备牛最迟在',
    beefOne_two:'天日龄时首次发情配种提醒',
    beefOne_three:'成母牛在产犊',
    beefOne_Four:'天后可以开始配种',
    beefTwo_one:'配种后',
    beefTwo_two:'天后进行孕检',
    beefThree_one:'孕检结果为怀孕，妊娠',
    beefThree_two:'天后进行分娩提醒',
    warningOne:'请选择品种',
    warningTwo:'请填写配种预警天数',
    warningThree:'请填产犊后配种间隔天数',
    warningFour:'请填写孕检预警天数',
    warningFive:'请填写分娩预警天数',
    confirm_deletion:"是否确认删除？",
    Operation_validation:"操作确认",
    Successfully_deleted:"删除成功",
    undeleted:"已取消删除",
}
export const beef_config_en = {
    
    add:'add',
    Breeding_warning:'Breeding warning',
    Pregnancy_warning:'Pregnancy warning',
    Parturition_warning:'Parturition warning',
    Creat_time:'Creat time',
    beefOne_one:'Reserve cattle should be reminded of first estrus at',
    beefOne_two:'days of age at the latest',
    beefOne_three:'Adult cows can start breeding',
    beefOne_Four:'days after calving',
    beefTwo_one:'Pregnancy test',
    beefTwo_two:'days after mating',
    beefThree_one:'The pregnancy test result is pregnant, and the delivery reminder is carried out after',
    beefThree_two:'days of pregnancy',
    warningOne:'Please select varieties',
    warningTwo:'Please enter breeding warning days',
    warningThree:'Please enter days between breeding after calving',
    warningFour:'Please enter the days of pregnancy test warning',
    warningFive:'Please enter the days of delivery warning',
    confirm_deletion:"Do you confirm deletion?",
    Operation_validation:"Operation validation",
    Successfully_deleted:"Successfully deleted",
    undeleted:"Undeleted",
}