export const menu_zh = {
    name: "畜牧数智化综合服务平台",
    tip_1: "vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看",
    tip_title: "浏览器不兼容通知",
    homeName: "首页",
    login: "登录",
    breed: {
        firmScreen: "数据大屏企业版",
        homeep: "企业数据大屏",
        newImmuneRecord: "免疫登记信息",
        newPreventRecord: "治疗登记信息",
        newoestrumHistory: "发情登记信息",
        newbreedingHistory: "配种登记信息",
        newpregnancyRecord: "孕检登记信息",
        newdeliveryRecord: "分娩登记信息",
        deathAnimal: "牲畜死亡信息",
        deathAnimaldetails: "死亡登记详情",
        animalFileinfoTile: "档案档案详情"
    },

    ai: {
        healthWarning: "健康预警",
        aiOestrusEstimate: "AI发情提醒",
        activityCurve: "活动量曲线",
        aiDeath: "AI模型死亡预警",
        nonlicetLeave: "非法离开预警",
        disposeIllegalDeparture: "查看/处理非法离栏",
        deviceWaring: "设备异常预警",
    },
    basic: {
        infoRegister: "耳标登记",
        newAllfile: "牲畜档案信息",
        reg_info_title: "信息登记详情",
        reg_labelNumber: "登记耳标",
        hisTemInfo_1: "历史温度信息",
        temperatureInfo: "温度信息管理",
    },
    config:{
        Alert_configuration:"牛只预警配置",
        Warning_setting:"数据大屏预警开关设置",
    },
    system:{
        Farm_manage:"养殖场管理",
        add_app_user:"APP新增用户",
        edit_app_user:"APP修改用户",
        User:"用户管理",
        App_User:"APP用户管理",
        
    },
}
// i18n.t('menu.ai.firmScreen')
export const menu_en = {
    name: "Animal Husbandry Digital Intelligence Comprehensive Service Platform",
    tip_1: "The vue-quill-editor component is not compatible with IE10 and below browsers, please use a newer version of the browser to view.",
    tip_title: "Browser Incompatibility Notice",
    homeName: "Home",
    login: "Login",
    breed: {
        firmScreen: "Enterprise Data Dashboard",
        homeep: "Home Data Dashboard",
        newImmuneRecord: "Immune Registration Information",
        newPreventRecord: "Treatment Registration Information",
        newoestrumHistory: "Estrus Registration Information",
        newbreedingHistory: "Breeding Registration Information",
        newpregnancyRecord: "Pregnancy Check Registration Information",
        newdeliveryRecord: "Delivery Registration Information",
        deathAnimal: "Animal Death Information",
        deathAnimaldetails: "Details of Death Registration",
        animalFileinfoTile: "Animal File Details"

    },
    ai: {
        healthWarning: "Health Warning",
        aiOestrusEstimate: "AI Estrus Reminder",
        activityCurve: "Activity Curve",
        aiDeath: "AI Model Death Warning",
        nonlicetLeave: "Illegal Departure Warning",
        disposeIllegalDeparture: "View/Handle Illegal Pen Exit",
        deviceWaring: "Device Abnormality Warning",

    },
    basic: {
        infoRegister: "Information Registration",
        newAllfile: "Animal File Information",
        reg_info_title: "Information Registration Details",
        reg_labelNumber: "Registration Ear Tag",
        hisTemInfo_1: "Historical Temperature Information",
        temperatureInfo: "Temperature Information Management",
    },
    config:{
        Alert_configuration:"Alert configuration",
        Warning_setting:"Warning setting",
    },
    system:{
        Farm_manage:"Farm manage",
        add_app_user:"Add app user",
        edit_app_user:"Edit app user",
        User:"User manage",
        App_User:"App User",
        
    },
};
