// 养殖企业决策看板
export const kanban_card_zh = {
    refresh: "刷新数据",
    summarizing: { //汇总
        title: "汇总",
        unit: {
            unit_one: "个",
            unit_two: "头",
        },
        farmTotal: "养殖场总数",
        labelNumberTotal: "耳标登记总数",
        animalTotal: "牲畜存栏总数",
        insureTotal: "投保牲畜总数",
        pledgedTotal: "抵/质押总数",
        liveTotal: "牲畜出栏总数"
    },
    pending: {
        title: "待处理情况",
        toDoList: {
            title: "待办事项",
            pendingListingApplication: "待出栏申请",
            deathPendingProcessing: "死亡待处理"
        },
        pendingAlert: {
            title: "待处理预警",
            illegalDepartureWarning: "非法离开预警",
            aIHealthWarning: "AI健康预警",
            Number_of_abnormal_ear_labels: "耳标异常数量",
            Abnormal_base_station_warning: "基站异常预警"

        }
    },
    detailed_statistics: {
        title: "详细统计",
        today_data: "今日数据",
        table: {
            date: "日期",
            Farm_name: "养殖场名称",
            Ear_label_registration_quantity: "耳标登记数量",
            Inventory_quantity: "存栏数量",
            Real_time_inventory_quantity: "实时盘点数量",
            Uncounted_quantity: "未盘点数量",
            Number_of_health_warnings: "健康预警数量",
            Number_of_illegal_departures: "非法离开数量",
            Number_of_deaths: "死亡数量",
            Elimination_quantity: "淘汰数量",

        }
    }
}


export const kanban_card_en = {
    refresh: "Refresh Data",
    summarizing: { //汇总
        title: "Summarizing",
        unit: {
            unit_one: "a", //个
            unit_two: "head",//头
        },
        farmTotal: "Total number of farms",
        labelNumberTotal: "Total number of ear tag registrations",
        animalTotal: "Total stock of livestock",
        insureTotal: "Total number of livestock insured",
        pledgedTotal: "Total amount pledged/pledged",
        liveTotal: "Total number of livestock put out"
    },
    pending: {
        title: "Pending situation",
        toDoList: {
            title: "To do list",
            pendingListingApplication: "Column apply",
            deathPendingProcessing: "Death pending"
        },
        pendingAlert: {
            title: "Pending alert",
            illegalDepartureWarning: "Illegal departure warning",
            aIHealthWarning: "AI health warning",
            Number_of_abnormal_ear_labels: "Number of abnormal ear labels",
            Abnormal_base_station_warning: "Abnormal base station warning"
        }
    },
    detailed_statistics: {
        title: "detailed statistics",
        today_data: "Today's data",
        table: {
            date: "date",
            Farm_name: "Farm",
            Ear_label_registration_quantity: "registration",
            Inventory_quantity: "Inventory",
            Real_time_inventory_quantity: "Real time inventory",
            Uncounted_quantity: "Uncounted",
            Number_of_health_warnings: "health warnings",
            Number_of_illegal_departures: "illegal departures",
            Number_of_deaths: "deaths",
            Elimination_quantity: "Elimination",
        }
    }
}