export const temperature_sport_zh = {
    isHealthy:"实时温度状态",
    isHealthy_1:"读取超时",
    isHealthy_2:"偏高",
    isHealthy_3:"偏低",
    isHealthy_4:"正常",
    tStatus:"实时温度状态",
    moveStatus:"实时运动状态",
    moveStatus_1:"读取超时",
    moveStatus_2:"高",
    moveStatus_3:"低",
    moveStatus_4:"正常",
    historyLabelNumber:"历史耳标编号",
    registerUrl:"戴标照片",
    no_photo:"暂无照片",
    temperature:"实时温度",
    temperature_1:"无温度",
    temperatureStatus:"实时温度状态",
    move:"实时运动值",
    no_date:"无数据",
    collectTime:"读取时间",
    today:"今日",
    week:"本周",
    title_1:"体温",
    title_2:"运动",
    historyteinfo:"历史温度信息",
    place_1:"请选择搜索条件或输入耳标号进行查询",
    title_3:"采食量",
}

export const temperature_sport_en = {  
    isHealthy:"Real-time temperature status",
    isHealthy_1:"Read timeout",
    isHealthy_2:"High",
    isHealthy_3:"Low",
    isHealthy_4:"Normal",
    tStatus:"Real-time temperature status",
    moveStatus:"Real-time movement status",
    moveStatus_1:"Read timeout",
    moveStatus_2:"High",
    moveStatus_3:"Low",
    moveStatus_4:"Normal",
    historyLabelNumber:"Historical ear tag number",
    registerUrl:"Wear label photo",
    no_photo:"No photo",
    temperature:"Real-time temperature",
    temperature_1:"No temperature",
    temperatureStatus:"Real-time temperature status",
    move:"Real-time movement value",
    no_date:"No data",
    collectTime:"Read time",
    today:"Today",
    week:"This week",
    title_1:"Temperature",
    title_2:"Motion",
    historyteinfo:"Historical temperature information",
    place_1:"Please select the search condition or enter the ear tag number to query",
    title_3:"Feed intake",
}