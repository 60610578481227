// 分娩

export const childbirth_zh = {
    childrenCount: "分娩数量",
    birthTime: "分娩日期",
    birthResult: "分娩结果",
    way_1: "正常",
    way_2: "异常",
    weight: "犊牛重量(公斤)",
    childOutput: "犊牛电子耳标号",
    sceneOperator: "保育员",
    operation_btn: "查看犊牛信息",
    unit: "头",
    no_info: "暂无图片信息",
    weight_1: "重量",
    unit_1: "公斤",
}
export const childbirth_en = {
    childrenCount: "Number of Births",
    birthTime: "Birth Date",
    birthResult: "Birth Result",
    way_1: "Normal",
    way_2: "Abnormal",
    weight: "Calf Weight (kilograms)",
    childOutput: "Calf Electronic Ear Tag Number",
    sceneOperator: "Nursery Personnel",
    operation_btn: "View",
    unit: "Head",
    no_info: "No Image",
    weight_1: "Weight",
    unit_1: "Kg",
};