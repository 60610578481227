export const oestrus_zh = {
    deviceType: "设备类型",
    type_1: "基站",
    type_2: "AI摄像头",
    alertTime: "预警时间",
    dealStatus: "处理状态",
    status_0: "待处理",
    status_1: "已处理",
    batch_ignore: "批量忽略",
    img: "抓拍照片",
    dealType: "处理方式",
    dealType_0: "未处理",
    dealType_1: "已处理",
    dealType_2: "忽略预警",
    dealType_3: "过期处理",
    dealUser: "处理人",
    dealTime: "处理时间",
    ec: "活动量曲线",
    place_1: "请选择需要忽略的数据",
    place_2: "确定要批量忽略选中的发情提醒数据吗？",
    place_3: "提示",
    place_4: "忽略成功",
}
export const oestrus_en = {
    deviceType: "Device Type",
    type_1: "Base Station",
    type_2: "AI Camera",
    alertTime: "Warning Time",
    dealStatus: "Deal Status",
    status_0: "Pending",
    status_1: "Handled",
    batch_ignore: "Batch Ignore",
    img: "Captured Photo",
    dealType: "Deal Type",
    dealType_0: "Unhandled",
    dealType_1: "Handled",
    dealType_2: "Ignore Warning",
    dealType_3: "Overdue Handling",
    dealUser: "Dealer",
    dealTime: "Deal Time",
    ec: "Activity Curve",
    place_1: "Please select the data to ignore",
    place_2: "Are you sure you want to batch ignore the selected breeding reminder data?",
    place_3: "Prompt",
    place_4: "Ignore Success"
};