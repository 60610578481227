import { Axios, AxiosData } from '@/common/http/http';
import { BasicsClass } from '@/common/BasicsClass';
import i18n from '@/vue-il8n';
import { customTitle } from "@/common/Views/basics/customTitle/customTitle";
interface Result {
    access_token: string;
    token_type: string;
    refresh_token: string;
    id: string;
    name: string;
    showMerchantsSelectionBox: boolean;
    groupCode: string;
}

export interface login {
    username: string;
    password: string;
}

export interface LoginUserMerchants {
    permissionMchId: string;
    id: string;
    username: string;
    password: string;
    company: string;
    name: string;
    mobile: string;
    email: string;
    mchId: string;
    status: number;
    createTime: string;
    updateTime: string;
    stopTime: string;
    createUserId: string;
    updateUserId: string;
    isAdmin: boolean;
    deleteFlag: boolean;
    roles: string[];
    roleIds: string;
    roleNames: string;
}

export class Login extends BasicsClass {

    private axios: Axios;

    constructor() {
        super();
        this.axios = new Axios();
    }


    public async submitForm(param: login, callBack: Function, errCb: Function) {
        try {

            const loginData = await this.axios.post<Result>('/animal/web/login', {
                username: param.username,
                password: param.password,
            }, false, true);

            // this.setLocalStorage(loginData);
            localStorage.setItem('id', loginData.data.id);
            localStorage.setItem('name', loginData.data.name);
            localStorage.setItem('groupCode', loginData.data.groupCode);
            console.log(loginData.data);
            
            new customTitle().homeTitle((res: any) => {
                const dataa = res.data;
                localStorage.setItem('homeTitle', dataa.homeTitle);
                localStorage.setItem('enterpriseScreenTitle', dataa.enterpriseScreenTitle)
                localStorage.setItem('welcomeTitle', dataa.welcomeTitle)
                localStorage.setItem('homeTitleType', dataa.homeTitleType)
            });
            if (loginData.data.showMerchantsSelectionBox) {

                localStorage.setItem('showMerchantsSelectionBox', 'yes');
            }

            // this.$store.commit('setIsTimer');

            // end

            // get menus

            this.getCurrentMenu(callBack)


        } catch (e) {

            if (e.message) {
                // this.vue.$message.error(e.message);
                errCb(e.message);
            }

        }

    }

    public logout(): void {
        this.BasicGet('/animal/web/logout', {}, false, false);
    }

    public getCurrentMenu(callBack: Function, type?: number, path?: string) {
        this.axios.get('/animal/web/menu/currentMenus', {}, true)
            .then((data: any) => {
                console.log(data);
                if (data.data.length !== 0) {
                    localStorage.setItem('menus', JSON.stringify(data.data));

                    const anies = data.data.filter((value: any) => value.url === '/bigData' || value.url === '/bigDataByPro');

                    if (type === 1) {
                        this.$router.go(0);
                        this.$router.push({ path })
                        return
                    }

                    if (anies.length === 2) {
                        for (let i = 0; i < data.data.length; i++) {
                            if (data.data[i].url === '/bigData') {
                                data.data.splice(i, 1);
                                localStorage.setItem('bigDataChange', '11');
                                break;
                            }
                        }

                        this.$router.push('/bigDataByPro');

                        return;
                    } else if (anies.length === 1) {

                        this.$router.push(anies[0].url);
                        return;
                    } else {
                        localStorage.removeItem('bigDataChange');
                    }

                    for (const i in data.data) {
                        if (data.data[i].children.length) {
                            console.log(data.data[i].children[0].url);
                            this.$router.push(data.data[i].children[0].url);
                            break;
                        }
                    }

                    callBack();

                } else {
                    this.vue.$message.warning(i18n.t('login.no_root') as string);
                }
            });
    }
}

