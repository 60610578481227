export const http_zh={
    repet:"重复请求",
    loading:"请稍候",
    err_1:"服务器正在维护，请稍等片刻",
}

export const http_en={
    repet:"Repeat request",
    loading:"Please wait",
    err_1:"The server is under maintenance, please wait a moment",
}