import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import '@/css/cssrest.styl';

import ElementUI from 'element-ui';
import ElementLocale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css';
ElementLocale.i18n((key, value) => i18n.t(key, value))

import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/index.css';

import tinymce from 'tinymce';
Vue.prototype.$tinymce = tinymce;

VXETable.setup({
	// 对组件内置的提示语进行国际化翻译
	i18n: (key, args) => i18n.t(key, args)
})

// 引入echarts
import echarts from 'echarts';

Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

//
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)


import i18n from '@/vue-il8n/index'

// this.$i18n.locale = "zh";
// i18n.locale = "zh"
// i18n.locale = "en"



import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
	Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

// highcharts
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(highcharts)
Vue.prototype.$highcharts = highcharts;

import scroll from 'vue-seamless-scroll';
Vue.use(scroll, { componentName: 'scroll-seamless' });

Vue.use(VXETable);

Vue.use(ElementUI);

import './assets/font/font.css';

import { preventReClick } from '@/common/js/preventReClick.js';
Vue.prototype.$preventReClick = preventReClick; // 节流函数
//MD5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// import video from 'video.js'
// import 'videojs-flash'
// Vue.prototype.$video = video

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video //引入Video播放器


//XLSX
import XLSX from 'xlsx';

//rsa
import JsEncrypt from 'jsencrypt'
Vue.prototype.$jsEncrypt = JsEncrypt


import ZmTreeOrg from 'zm-tree-org';
import "zm-tree-org/lib/zm-tree-org.css";
Vue.use(ZmTreeOrg);

// //vue-amap
// import VueAMap from 'vue-amap';
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: '95fa72137f4263f8e64ae01f766ad09c',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
//   // 默认高德 sdk 版本为 1.4.4
//   v: '1.4.4'
// });

import axios, { AxiosStatic } from 'axios';
Vue.use((v) => {
	// v.prototype.$video = Video;
	v.prototype.$axios = axios;
	v.prototype.$getResourceCodes = function (key: string) {
		// return this.$store.state.resourceCodes.includes(key);
		return true;
	};
});

declare module 'Vue/types/vue' {
	interface Vue {
		$axios: AxiosStatic;
		$getResourceCodes: Function;
		$video: any;
	}
}

// import { preventReClick }from '@/common/js/preventReClick';

// Vue.prototype.$preventReClick = preventReClick; // 节流函数

let PathArray: any[] = ['/animalFile', '/animalTemperatureInfo', '/ScreenAdressGuiZhou', '/productComparison', '/landingPlan', '/ecologicalMap'
	, '/blockchainTraceability', '/LianTongBankScreen', '/LianTongInsuranceScreen', '/LianTongEnterpriseScreen']

// 使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
	PathArray.forEach((itemPath: string) => { // 对外对接  特定 加的判断
		if (to.path == itemPath) {
			if (to.query.key != undefined) {
				if (to.query.key == 'KtXabZDUGL6MZ0Nm') {
					window.localStorage.setItem('id', 'KtXabZDUGL6MZ0Nm')
				}
			}
		}
	});
	document.title = `${to.meta.title} | ${i18n.t('menu.name')}`;
	// document.title = `${to.meta.title}`;
	const role = localStorage.getItem('id');
	if (!role && to.path !== '/login') {
		next('/login');
	} else {
		// 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
		if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
			Vue.prototype.$alert(i18n.t('menu.tip_1'), i18n.t('menu.tip_title'), {
				confirmButtonText: i18n.t('common.confirm'),
			});
		} else {
			next();
		}
	}
});
// end




Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
