import { BasicsClass } from '@/common/BasicsClass';
import { Axios } from '@/common/http/http';
import { floatJson } from '@/common/deepLoop/deepLoop';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { Util } from '@/common/Views/comm/util';

export interface Result {
    id: string;
    name: string;
    url: string;
    children: string;
    order: string;
    resources: Result[];
}

export interface TreeResult {
    id: string;
    name: string;
    url: string;
    children: string;
    order: string;
}

export interface ComboList {
    createTime: string;
    createUserId: string;
    deleteFlag: true;
    groupCode: string;
    id: string;
    mobile: string;
    name: string;
    permissionFarmIds: string;
    permissionGroupCode: string;
    person: string;
    status: boolean
    typeIds: string;
    updateTime: string;
    updateUserId: string;
    destinationTypeId: string;
}

export class Column extends BasicsClass {

    private axios: Axios;
    constructor() {
        super();
        this.axios = new Axios();
    }

    public props: any = {
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
        // lazy: true,
        // lazyLoad(node: any, resolve: any){
        //     const column: Column = new Column();
        //     if(node.level === 5){
        //         resolve()
        //         return
        //     }
        //     column.getTissuc(node.value, (res: any) => {
        //         console.log(res.data)
        //         const nodes = res.data
        //         if (node.level === 4) {
        //             nodes.forEach((item: any) => {
        //                 item.leaf = item.level >= 4
        //             })    
        //         }
        //         resolve(nodes)
        //     })
        // }
    }



    public props1: any = {
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
        lazy: true,
        lazyLoad(node: any, resolve: any) {
            console.log(node)
            console.log(resolve(123))
            const column: Column = new Column();
            // if (node.level === 5) {
            //     return
            // }
            column.getTissuc2(node.value, 1, (res: any) => {
                // if(res.data[0].level == 4){
                //     console.log(1)
                //     for(var i in res.data){

                //     }
                // }else{
                //     resolve(res.data)
                // }
                console.log(res.data)
                // resolve(res.data)
                try {
                    let nodes = res.data
                    nodes.forEach((item: any) => {
                        item.leaf = (item.level === 4)
                    })
                    // console.log(nodes)
                    resolve(nodes)
                } catch (e) {

                }

                // if (node.level === 4) {

                // }

            })
        }
    }

    public getTreeData2(data: any) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {
            if (data[i].children.length < 1) {
                // children若为空数组，则将children设为undefined
                data[i].children = undefined;
            } else {
                // children若不为空数组，则继续 递归调用 本方法
                this.getTreeData2(data[i].children);
            }
        }
        console.log(data)
        return data;
    }

    public props2: any = {
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
        lazy: true,
        lazyLoad(node: any, resolve: any) {
            const column: Column = new Column();
            if (node.level < 6) {
                console.log(node)

                column.getTissuc2(node.value, 1, (res: any) => {
                    console.log(res)
                    if (node.level === 4) {
                        console.log(3)
                        resolve(column.getTreeData2(res.data))
                    } else {
                        console.log(2)
                        resolve(res.data)

                    }
                })
            }

        }
    }

    public async getTreeData(url: string = '/animal/web/menu/treeList', flag: number = 1, platform: number = 1) {
        let treeData: Result[];
        try {
            const data = await this.axios.get<Result[]>(url, { flag, platform });
            treeData = data.data;
        } catch (e) {
            this.vue.$message.error(e.message);
            treeData = [];
        }
        return treeData;
    }

    public getTreeData1(pid: any,cb:Function): void {
        if (pid === undefined) {
            pid = -1
        }
        this.BasicGet('/animal/web/provinceCityDistrict/getGroupCodeForUser', {pid,flag:1}, false, true, cb);
    }

    public getFarm(groupCode: string, cb: Function, destinationTypeId: string = ''): void {
        this.BasicGet('/animal/web/farm/comboList', { groupCode, destinationTypeId }, false, true, cb);
    }

    public getFarmTwo(groupCode: string, cb: Function, destinationTypeId: string = ''): void {
        this.BasicGet('/animal/web/farm/comboList2', { groupCode, destinationTypeId }, false, true, cb);
    }

    public getFarm2(groupCode: string, cb: Function): void {
        this.BasicGet('/animal/web/thirdMonitor/getFarms', { groupCode}, false, true, cb);
    }

    public getFarms(groupCode: string, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenDataStatInsurance/getFarms', { groupCode }, false, true, cb);
    }

    public getManyCountForCompany(form: any, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenDataStatCompany/getManyCountForCompany', {
            groupCode: form.groupCode[form.groupCode.length - 1],
            farmId: form.farmId
        }, false, true, cb);
    }

    public PendingMatters(form: any, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenDataStatCompany/pendingMatters', {
            groupCode: form.groupCode[form.groupCode.length - 1],
            farmId: form.farmId
        }, false, true, cb);
    }

    public PendingWarning(form: any, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenDataStatCompany/pendingWarning', {
            groupCode: form.groupCode[form.groupCode.length - 1],
            farmId: form.farmId
        }, false, true, cb);
    }

    public getdetail(selindex: any,tablePage:any, cb: Function): void {
        this.BasicGet('/animal/web/enterpriseDetail/detail', {
            ...tablePage,
            type: selindex
        }, false, true, cb);
    }



    public exportDetailList(times: any): void {
        const time = Util.handleStartTimeAndEndTime(times);
        this.getFile('/animal/web/enterpriseDetail/exportDetailList', {
            ...time,
        })

    }

    //////////
    public getBaseData(form: any, cb: Function): void {
        if (form.groupCode.length == 0) {
            this.BasicGet('/animal/web/bigScreenDataStatInsurance/getBaseData', {
                groupCode: '0',
                farmId: form.farmId
            }, false, true, cb);
        } else {
            this.BasicGet('/animal/web/bigScreenDataStatInsurance/getBaseData', {
                groupCode: form.groupCode[form.groupCode.length - 1],
                farmId: form.farmId
            }, false, true, cb);
        }

    }

    public getBaseData2(form: any, cb: Function): void {
        if (form.groupCode.length == 0) {
            this.BasicGet('/animal/web/bigScreenDataStatBank/getBaseData', {
                groupCode: '0',
                farmId: form.farmId
            }, false, true, cb);
        } else {
            this.BasicGet('/animal/web/bigScreenDataStatBank/getBaseData', {
                groupCode: form.groupCode[form.groupCode.length - 1],
                farmId: form.farmId
            }, false, true, cb);
        }

    }

    public getNeedDealCountForInsurance(form: any, cb: Function): void {
        this.BasicGet('/animal/web/bigScreenDataStatInsurance/getNeedDealCountForInsurance', {
            groupCode: form.groupCode[form.groupCode.length - 1],
            farmId: form.farmId
        }, false, true, cb);
    }

    public getNeedDealCountForInsurance2(form: any, cb: Function): void {
        this.BasicGet('/animal/web/newBankLargeScreen/pendingData', {
            id: form.groupCode[form.groupCode.length - 1],
            farmId: form.farmId
        }, false, true, cb);
    }


    public companyGroupCode(cb: Function): void {
        this.BasicGet('/animal/web/bigScreenDataStatCompany/companyGroupCode', {}, false, true, cb);
    }

    public getgroupCode(cb: Function): void {
        this.BasicGet('/animal/web/bigScreenDataStatInsurance/groupCode', {}, false, true, cb);
    }

    public getgroupCode2(cb: Function): void {
        this.BasicGet('/animal/web/bigScreenDataStatBank/groupCode', {}, false, true, cb);
    }

    public getFarms2(groupCode: string, cb: Function,): void {
        this.BasicGet('/animal/web/bigScreenDataStatBank/getFarms', { groupCode }, false, true, cb);
    }

    public getVaccinesConf(cb: Function): void {
        this.BasicGet('/animal/web/MedicineConf/getVaccinesConf', {}, false, true, cb)
    }

    public addFarmBuilding(parentId: string, cb: Function, placeName: string, farmId: string): void {
        this.BasicPost('/animal/web/animalFarmPlace/addFarmBuilding', {
            parentId, placeName, farmId
        }, true, true, true, cb)
    }

    public checkLabelList(labelList: any, cb: Function, flag: string): void {
        this.BasicPost('/animal/web/animalMark/checkLabelList', {
            labelList, flag
        }, false, true, true, cb)
    }

    public animalSave(cb: Function, form: string): void {
        form.markList.forEach((val:any) => {
            delete val.err
        });
        this.BasicPost('/animal/web/animalMark/animalSave', form, true, true, true, cb)
    }


    public getHouse(cb: Function, farmId?: string): void {
        this.BasicGet('/animal/web/animalMark/houseList', { farmId }, false, true, cb)
    }

    public getColumn(form: any, cb: Function): void {
        this.BasicGet('/animal/web/animalMark/columnList', form, false, true, cb)
    }

    public getCorporation(isInternational:any,cb: Function): void {
        this.BasicGet('/animal/web/farm/getFarmCompany', {isInternational}, false, true, cb)
    }

    public getAll(groupCode: string, cb: Function): void {
        this.BasicGet('/animal/web/farm/comboListForDevice', { groupCode }, false, true, cb);
    }

    public getdeathForReason(cb: Function): void {
        this.BasicGet('/animal/web/deathAnimal/getDeathReasonList', {}, false, false, cb);
    }


    public getSlaughter(groupCode: string, cb: Function, destinationTypeId: string = ''): void {
        this.BasicGet('/animal/web/slaughter/list', { groupCode }, false, true, cb);
    }

    public getHarmless(groupCode: string, cb: Function, destinationTypeId: string = ''): void {
        this.BasicGet('/animal/web/harmlesstreatment/list', { groupCode }, false, true, cb);
    }

    public getParentId(data: floatJson, id: string, parenId: string = '1'): string[] {
        let result: string[] = [];
        if (data[id] !== parenId) {
            result = this.getParentId(data, data[id], parenId);
            result.push(id);
        } else {
            result.push(id);
        }

        return result;
    }

    public getCompany(cb: Function): void {  // 获取保险公司列表
        this.BasicGet('/animal/web/insurance/list', {}, false, true, cb)
    }
    public getCompany1(cb: Function): void {  // 获取保险公司列表
        this.BasicGet('/animal/web/insurance/listV3085', {}, false, true, cb)
    }
    public getCompany2(cb: Function): void {  // 获取保险公司列表
        this.BasicGet('/animal/web/insuranceConfig/list', {}, false, true, cb)
    }

    public getMainCompanies(cb: Function): void {  // 获取公司列表
        this.BasicGet('/animal/web/companyInfo/listAll', {}, false, true, cb)
    }

    public getTissuc(pid: any, cb: Function): void {
        if (pid === undefined) {
            pid = -1
        }
        this.BasicGet('/animal/web/provinceCityDistrict/getGroupCodeForUser', { pid }, false, false, cb)
    }

    public getTissuc2(pid: any, flag: number = 1, cb: Function): void {
        if (pid === undefined) {
            pid = -1
        }
        this.BasicGet('/animal/web/provinceCityDistrict/getGroupCodeForUser', { pid, flag }, false, false, cb)
    }
    public getDestination(cb: Function): void {

        this.BasicGet('/animal/web/destinationType/value', {}, false, false, cb)
    }
    public addConfig(cb: Function, form: any): void {

        this.BasicPost('/animal/web/insuranceConfig/addInsuranceConfig', form, true, true, true, cb)
    }

    public editConfig(cb: Function, form: any): void {

        this.BasicPost('/animal/web/insuranceConfig/editInsuranceConfig', form, true, true, true, cb)
    }
    public getcomboList(cb: Function, form: any): void {

        this.BasicGet('/animal/web/insuranceConfig/comboList', form, false, false, cb)
    }

    //获取公司配置列表
    public getcompanyInfo(cb: Function, form: any): void {
        this.BasicGet('/animal/web/companyInfo/page', form, false, false, cb)
    }

    public getDetail(cb: Function, id: any): void {
        this.BasicGet('/animal/web/insuranceConfig/getDetail', { id }, false, false, cb)
    }

    public setStatus(cb: Function, forms: any): void {
        this.BasicGet('/animal/web/insuranceConfig/setStatus', forms, true, true, cb)
    }

    public typeList(cb: Function): void {
        this.BasicGet('/animal/web/insuranceConfig/typeList', {}, false, false, cb)
    }

    //状态启用/关闭接口
    public setenable(cb: Function, forms: any): void {
        this.BasicPost('/animal/web/companyInfo/enable', forms, true, true, true, cb)
    }
    //获取公司详情
    public getinfo(cb: Function, id: string): void {
        this.BasicGet('/animal/web/companyInfo/info', { id }, false, false, cb)
    }

    //修改公司详情
    public updatecompanyInfo(cb: Function, form: any): void {
        this.BasicPost('/animal/web/companyInfo/update', form, true, true, true, cb)
    }

    //新增公司详情
    public addcompanyInfo(cb: Function, form: any): void {
        this.BasicPost('/animal/web/companyInfo/add', form, true, true, true, cb)
    }

    //服务费摊销报表列表
    public getamortizationList(cb: Function, form: any): void {
        this.BasicGet('/animal/web/amortization/amortizationList', form, false, false, cb)
    }

    //服务费摊销报表详情
    public getamortizationDetail(cb: Function, form: any): void {
        this.BasicGet('/animal/web/amortization/amortizationDetail', form, false, false, cb)
    }

    //合同编号列表
    public contractNumlist(cb: Function): void {
        this.BasicGet('/animal/web/contract/contractNum/list', {}, false, false, cb)
    }

    //收款方名称条件列表
    public getRecordListForBeneficiary(cb: Function): void {
        this.BasicGet('/animal/web/amortization/getRecordListForBeneficiary', {}, false, false, cb)
    }
    //付款方名称条件列表
    public getRecordListForPayer(cb: Function): void {
        this.BasicGet('/animal/web/amortization/getRecordListForPayer', {}, false, false, cb)
    }

    //所属养殖场条件列表
    public getRecordListForFarm(cb: Function): void {
        this.BasicGet('/animal/web/amortization/getRecordListForFarm', {}, false, false, cb)
    }
    //服务期限条件列表
    public getRecordListForServiceTerm(cb: Function): void {
        this.BasicGet('/animal/web/amortization/getRecordListForServiceTerm', {}, false, false, cb)
    }

    //摊销批次选择条件列表
    public getRecordListForBatchNum(cb: Function): void {
        this.BasicGet('/animal/web/amortization/getRecordListForBatchNum', {}, false, false, cb)
    }
    // 导出服务费摊销报表
    public exportList(form: any, tablePage: HandleTable): void {
        this.getFile('/animal/web/amortization/exportList', {
            ...form,
            ...tablePage
        })
    }




    //公司往来费摊销报表列表
    public getamortizationList2(cb: Function, form: any): void {
        this.BasicGet('/animal/web/amortizationCompany/amortizationList', form, false, false, cb)
    }

    //公司往来费摊销报表详情
    public getamortizationDetail2(cb: Function, form: any): void {
        this.BasicGet('/animal/web/amortizationCompany/amortizationDetail', form, false, true, cb)
    }
    //收款方名称条件列表
    public getRecordListForBeneficiary2(cb: Function): void {
        this.BasicGet('/animal/web/amortizationCompany/getRecordListForBeneficiary', {}, false, false, cb)
    }
    //付款方名称条件列表
    public getRecordListForPayer2(cb: Function): void {
        this.BasicGet('/animal/web/amortizationCompany/getRecordListForPayer', {}, false, false, cb)
    }

    //所属养殖场条件列表
    public getRecordListForFarm2(cb: Function): void {
        this.BasicGet('/animal/web/amortizationCompany/getRecordListForFarm', {}, false, false, cb)
    }
    //公司往来期限条件列表
    public getRecordListForServiceTerm2(cb: Function): void {
        this.BasicGet('/animal/web/amortizationCompany/getRecordListForServiceTerm', {}, false, false, cb)
    }

    //摊销批次选择条件列表
    public getRecordListForBatchNum2(cb: Function): void {
        this.BasicGet('/animal/web/amortizationCompany/getRecordListForBatchNum', {}, false, false, cb)
    }
    // 导出公司往来费摊销报表
    public exportList2(form: any, tablePage: HandleTable): void {
        this.getFile('/animal/web/amortizationCompany/exportList', {
            ...form,
            ...tablePage
        })
    }

    //审核列表以及详情
    public getauditAndChargeSum(cb: Function, form: any, tablePage: HandleTable): void {
        this.BasicGet('/animal/web/auditAndChargeSum/list', { ...form, ...tablePage }, false, false, cb)
    }

    //审核
    public auditBatch(cb: Function, form: any): void {
        this.BasicGet('/animal/web/auditAndChargeSum/auditBatch', { ...form }, false, false, cb)
    }

    //新增合同
    public addcontract(cb: Function, form: any): void {
        this.BasicPost('/animal/web/contract/add', form, true, true, true, cb)
    }

    //收款方
    public getpayeeNamelist(cb: Function): void {
        this.BasicGet('/animal/web/contract/payeeName/list2', {}, false, false, cb)
    }
    //付款方
    public getpayerNamelist(cb: Function, payerId: number): void {
        this.BasicGet('/animal/web/contract/payerName/list2', { payerId }, false, false, cb)
    }

    //合同列表
    public getcontractlist(cb: Function, form: any, tablePage: any): void {
        this.BasicGet('/animal/web/contract/list', { ...form, ...tablePage }, false, false, cb)
    }
    //合同导出
    public contractexportList(form: any): void {
        this.getFile('/animal/web/contract/exportList', { ...form })
    }

    //合同详情
    public contractdetail(cb: Function, form: any): void {
        this.BasicGet('/animal/web/contract/detail', { ...form }, false, false, cb)
    }
    //图片上传参数
    public ossconfig(cb: Function): void {
        this.BasicGet('/animal/web/common/oss/config', {}, false, false, cb)
    }

    //图片上传
    public uploadImage(cb: Function, strBase64: string): void {
        this.$headers['Content-Type'] = 'multipart/form-data';
        let fData = new FormData()
        if (strBase64) {
            fData.append("base64", strBase64)
        }
        this.vue.$axios.post("/animal/web/common/oss/uploadImage",
            fData,
            {
                headers: this.$headers,
            }).then((res: any) => {
                cb(res.data)
            })
    }
    //文件上传
    public uploadFile(modelName:any,cb: Function, file: any): void {
        this.$headers['Content-Type'] = 'multipart/form-data';
        let fData = new FormData()
        fData.append("file", file);
        fData.append("modelName", modelName);
        this.vue.$axios.post("/animal/web/common/oss/upload ",
            fData,
            {
                headers: this.$headers,
            }).then((res: any) => {
                cb(res.data)
            })
    }

    //修改合同
    public upcontract(cb: Function, form: any): void {
        this.BasicPost('/animal/web/contract/update', form, true, true, true, cb)
    }
    public getdeathAuditDetail(cb: Function, form: any): void {
        this.BasicGet('/animal/web/deathAuditMark/deathAuditDetail', form, false, false, cb)
    }

    public deathAuditDetail(cb: Function, form: any): void {
        this.BasicPost('/animal/web/deathAuditMark/auditDeath', form, true, true, true, cb)
    }

    public exportlableList(form: any, type: number = 0): void {
        console.log(form.groupCode.length)
        const time = Util.handleStartTimeAndEndTime(form.time);
        if (type == -1) {
            if (form.groupCode.length > 0) {
                this.getFile('/animal/web/animalMark/animalExport', {
                    ...form,
                    ...time,
                    farmId: -1,
                    groupCode: form.groupCode[form.groupCode.length - 1],
                })
            } else {
                this.getFile('/animal/web/animalMark/animalExport', {
                    ...form,
                    ...time,
                    farmId: -1,
                })
            }

        } else {
            if (form.groupCode.length > 0) {
                this.getFile('/animal/web/animalMark/animalExport', {
                    ...form,
                    ...time,
                    groupCode: form.groupCode[form.groupCode.length - 1],
                })
            } else {
                this.getFile('/animal/web/animalMark/animalExport', {
                    ...form,
                    ...time,
                })
            }
        }

    }


    public exportlableList2(form: any): void {
        console.log(form.groupCode.length)
        const time = Util.handleStartTimeAndEndTime(form.time);
        if (form.groupCode.length > 0) {
            this.getFile('/animal/web/breedAndInsuredStatistics/exportBreedList', {
                ...form,
                ...time,
                groupCode: form.groupCode[form.groupCode.length - 1],
            })
        } else {
            this.getFile('/animal/web/breedAndInsuredStatistics/exportBreedList', {
                ...form,
                ...time,
            })
        }

    }

    public animalDel(cb: Function, form: any): void {
        this.BasicPost('/animal/web/animalMark/animalDel', form, false, true, true, cb)
    }

    public exportLabelTemplate(): void {
        this.getFile('/animal/web/animalMark/exportLabelTemplate', {})
    }

    //健康预警
    public batchDeal(cb: Function, form: any): void {
        this.BasicPost('/animal/web/aiHealthAlarm/batchDeal', form, true, true, true, cb)
    }
    //非法离开
    public batchDispose(cb: Function, form: any): void {
        this.BasicPost('/animal/web/leaveRecordPre/batchDispose', form, true, true, true, cb)
    }
    //死亡判别
    public batchCheck(cb: Function, form: any): void {
        this.BasicPost('/animal/web/aiManualCheck/batchCheck', form, true, true, true, cb)
    }


    //手动新增健康预警
    public addList(cb: Function, form: any): void {
        this.vue.$axios({
            method: "post",
            url: "/animal/web/aiHealthAlarm/addList",
            data: form,
        })
            .then((res: any) => {
                cb(res.data);
            });
    }

    //手动新增非法离开
    public batchAdd(cb: Function, form: any): void {
        this.vue.$axios({
            method: "post",
            url: "/animal/web/leaveRecordPre/batchAdd",
            data: form,
        })
            .then((res: any) => {
                cb(res.data);
            });
    }

    //手动新增死亡判别
    public deathaddList(cb: Function, form: any): void {
        this.vue.$axios({
            method: "post",
            url: "/animal/web/aiManualCheck/addList",
            data: form,
        })
            .then((res: any) => {
                cb(res.data);
            });
    }



}
