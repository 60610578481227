// 孕检
export const test_zh = {
    checkResult: "孕检结果",
    result_1: "孕检阴性",
    result_2: "孕检阳性",
    result_3: "流产",
    result_4: "反情",
    result_5: "空胎",
    checkTime: "孕检日期",
    sceneOperator: "保育员",
}
export const test_en = {
    checkResult: "Pregnancy Check Result",
    result_1: "Negative Pregnancy Check",
    result_2: "Positive Pregnancy Check",
    result_3: "Miscarriage",
    result_4: "Anti-heat",
    result_5: "Empty Pregnancy",
    checkTime: "Pregnancy Check Date",
    sceneOperator: "Nursery Worker",
}