export const common_zh = {
    seq: "序号",
    farm: "养殖场",
    house: "养殖舍",
    column: "养殖栏",
    farm_group: "养殖地",
    farm_enterprise: "养殖企业",
    breedType: "品种类别",
    own_group_code: "所属机构",
    own_company: "所属企业",
    own_farm: "所属养殖场",
    own_house: "所属养殖舍",
    own_column: "所属栏",
    own_hc: "所属栋/舍",
    labelNumber: "生物芯片耳标号",
    oldLabelNumber: "电子耳标号",
    yuanLabelNumber: "原场耳标号",
    ageDay: "当前日龄",
    remark: "备注信息",
    registerTime: "登记时间",
    registerAge: "登记日龄",
    Age: "日龄",
    registerWeight: "登记体重",
    registrant: "登记人",
    operation: "操作",
    cencel: "取消",
    confirm: "确定",
    language:'切换语言',
    Switch_Chinese:'中文',
    Switch_English:'英文',
    Log_Out:'退出登录',
    welcome:'欢迎使用畜牧数智化综合服务平台',
    head:'头',
    btn: {
        search: "查询",
        reset: "重置",
        export: "导出",
        import: "导入",
        save: "保存",
    },
    placeholder: {
        enter: "请输入",
        entertwo: "请输入",
        select: "请选择",
        all: "全部",
        startDate: "开始日期",
        unit: "至",
        endDate: "结束日期",
        day: "天",
        jin: "斤",
        kilogram:'公斤',
    },
    table_btn: {
        detail: "详情",
        del: "删除",
        more: "更多",
        edit: "编辑",
        audit: "审核",
    },
    bigScreen:{
        video:{
            deviceCode:"设备编号",
            livestockCount:"戴标数",
            inventoryCount:"盘点数",
            status:"设备离线状态",
            lastTime:"最后一次在线时间",
            copysuccess:"复制成功",
            copyfail:"浏览器不支持自动复制到剪切板"
        }
    }
}
export const common_en = {
    seq: "Seq",
    farm: "Farm",
    house: "Breeding House",
    column: "Breeding Column",
    farm_group: "Breeding Site",
    farm_enterprise: "Breeding Enterprise",
    breedType: "Breed Type",
    own_group_code: "Own Group Code",
    own_company: "Own Company",
    own_farm: "Own Farm",
    own_house: "Own Breeding house",
    own_hc: "Own Building/Shed",
    own_column: "Own Column",
    labelNumber: "Biochip Ear Tag",
    oldLabelNumber: "Electronic Ear Tag",
    yuanLabelNumber: "Original Farm Ear Tag",
    ageDay: "Current Day-old",
    remark: "Remark Information",
    registerTime: "Registration Time",
    registerAge: "Registration Age",
    registerWeight: "Registration Weight",
    registrant: "Registrant",
    operation: "Operation",
    cencel: "Cancel",
    confirm: "Confirm",
    language:'language',
    Switch_Chinese:'Chinese',
    Switch_English:'English',
    welcome:'Welcome to Livestock Service Platform',
    Log_Out:'Log Out',
    Age: "Age",
    head:'head',
    btn: {
        search: "Search",
        reset: "Reset",
        export: "Export",
        del: "Delete",
        import:"Import",
        save: "Save",
    },
    placeholder: {
        enter: "Please enter ",
        entertwo: "enter",
        select: "Please select ",
        all: "All",
        startDate: "Start Date",
        unit: "to",
        endDate: "End Date",
        day: "Days",
        jin: "jin",
        kilogram:'Kg',
    },
    table_btn: {
        detail: "Detail",
        del: "Delete",
        more: "More",
        edit: "Edit",
        audit: "Audit",
    },
    bigScreen:{
        video:{
            deviceCode:"Device Number",
            livestockCount:"Wearing Tag Number",
            inventoryCount:"Inventory Number",
            status:"Device Offline Status",
            lastTime:"Last Online Time",
            copysuccess:"Copy success",
            copyfail:"The browser does not support automatic copying to the clipboard"
        }
    }
};