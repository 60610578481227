
// 死亡
export const die_zh = {
    own_insurance: "所属保险",
    dealTime: "处理时间",
    deathReason: "死亡原因",
    prevLabelNo: "防疫编号",
    auditStatus: "审核状态",
    insuranceCompanyName: "保险公司名称",
    typeConfName: "品种种类",
    dealUser: "处理人",
    auditTime: "审核时间",
    status_0: "待审核",
    status_1: "审核通过",
    status_2: "已拒绝",
    status_3: "无需审核",
    manage: "处理",
    detail: {
        basic_info: "基本信息",
        die_time: "死亡时间",
        deathRemark: "审核备注",
        audit_info: "审核信息",
        img_1: "身长测量照片",
        img_2: "电子耳标照片",
        img_3: "防疫耳标照片",
        img_4: "无害化处理照片",
        curve_info: "曲线",
        auditOpt: "审核操作",
        reason: "理由",
        pass: "通过",
        true_down: "拒绝",
        consent: "同意申请",
        place: "请认真核对所有登记信息 确认无误再审核通过",
        err_tip: "拒绝需要填写拒绝理由，方便后期相关工作人员查看",
        day: "今日",
        week: "本周",
        temperature: "体温",
        sport: "运动",
        e_title: "采食量",
    }
}
export const die_en = {
    own_insurance: "Own Insurance",
    dealTime: "Deal Time",
    deathReason: "Death Reason",
    prevLabelNo: "Previous Label Number",
    auditStatus: "Audit Status",
    insuranceCompanyName: "Insurance Company Name",
    typeConfName: "Type Confirmation Name",
    dealUser: "Deal User",
    auditTime: "Audit Time",
    status_0: "Pending Review",
    status_1: "Audited and Approved",
    status_2: "Rejected",
    status_3: "No Need for Review",
    manage: "Manage",
    detail: {
        basic_info: "Basic Info",
        die_time: "Death Time",
        deathRemark: "Audit Remark",
        audit_info: "Audit Info",
        img_1: "Body Length Measurement Photo",
        img_2: "Electronic Ear Tag Photo",
        img_3: "Preventive Medicine Ear Tag Photo",
        img_4: "Disposal Photo",
        curve_info: "Curve Info",
        auditOpt: "Audit Option",
        reason: "Reason",
        pass: "Pass",
        true_down: "Reject",
        consent: "Consent to Apply",
        place: "Please carefully check all registration information and confirm that it is correct before approving.",
        err_tip: "To refuse, you need to fill in the reason for refusal, which is convenient for the relevant staff to view later",
        day: "Today",
        week: "This week",
        temperature: "Temperature",
        sport: "Sports",
        e_title: "Feed Intake",
    }
};