export const health_zh = {
    isHandler: "处理状态",
    handler_0: "未处理",
    handler_1: "已处理",
    handler_2: "过期处理",
    batch_ignore: "批量忽略",
    warningTime: "预警时间",
    count: "预警次数",
    times: "次",
    handlerMethodMsg: "处理方式",
    handlerUser: "处理人",
    handlerTime: "处理时间",
    place_1: "请选择需要忽略的数据",
    place_2: "确定要批量忽略选中的健康预警数据吗？",
    place_3: "提示",
    place_4: "忽略成功",
}
export const health_en = {
    isHandler: "Handling Status",
    handler_0: "Unhandled",
    handler_1: "Handled",
    handler_2: "Expired Handling",
    batch_ignore: "Batch Ignore",
    warningTime: "Warning Time",
    count: "Warning Count",
    times: "times",
    handlerMethodMsg: "Handling Method",
    handlerUser: "Handler",
    handlerTime: "Handling Time",
    place_1: "Please select the data to ignore",
    place_2: "Are you sure you want to batch ignore the selected health warnings?",
    place_3: "Prompt",
    place_4: "Ignore Success",
};