export const app_user_zh = {
  firm: "关联企业",
  status_0: "已启用",
  status_1: "已停用",
  status_2: "已锁定",
  status_3: "已注销",
  founder: "创建人",
  bankIds: "关联银行",
  insurance: "关联保险",
  Login_lock: "登录锁定",
  Aquaculture_manager: "养殖企业管理员",
  search: "搜索.......",
  enter_name: "请输入姓名",
  enter_mobile: "请输入手机号码",
  select_lending_bank:"选择贷款银行",
  Affiliated_enterprise:"请选择关联企业",
  select_farm:"请选择养殖场",
  select_Enable_status:"请选择启用状态",
  Demo_account:"演示账号",
  Bank_organization:"银行组织机构",
  select_bank:"请选择银行",
  Insurance_organization:"保险公司组织机构",
  select_insurance:"请选择保险公司",
  Affiliated_slaughterhouse:"关联屠宰场",
  Slaughterhouse_organization:"屠宰场组织机构",
  select_slaughterhouse:"请选择屠宰场",
  Associated_farm:"关联养殖场",
  Modify_membership:"修改隶属关系",
  Darg_error:"拖拽次数过多，树结构发生混乱，请关闭窗口重新打开再进行操作",
  Modified_successfully:"修改成功",
  Authority_role:"权限角色",
  drag_drop:"请通过拖拽方式选择隶属关系"
}
export const app_user_en = {
  firm: "Firm",
  status_0: "Enable",
  status_1: "Disable",
  status_2: "Lock",
  status_3: "Logout",
  founder: "Founder",
  bankIds: "Bank",
  insurance: "Insurance",
  Login_lock: "Login Lock",
  Aquaculture_manager: "Aquaculture manager",
  search: "Search.......",
  enter_name: "Please enter name",
  enter_mobile: "Please enter mobile",
  select_lending_bank:"select lending bank",
  Affiliated_enterprise:"Please select associated enterprise",
  select_farm:"Please select the farm",
  select_Enable_status:"Please select the enable status",
  Demo_account:"Demo account",
  Bank_organization:"Bank organization",
  select_bank:"Please select the bank",
  Insurance_organization:"Insurance organization",
  select_insurance:"Please select the insurance company",
  Affiliated_slaughterhouse:"Affiliated slaughterhouse",
  Slaughterhouse_organization:"Slaughterhouse organization",
  select_slaughterhouse:"Please select the slaughterhouse",
  Associated_farm:"Associated farm",
  Modify_membership:"Modify membership",
  Darg_error:"Error. Please close the window and open it again",
  Modified_successfully:"Modified successfully",
  Authority_role:"Authority role",
  drag_drop:"Select a membership by drag and drop"
}