// 免疫登记信息

export const immunization_zh = {
    search: {
        data: {
            title: "免疫日期",
        },
        time: {
            title: "登记时间",
        }
    },
    table: {
        Immunization_date: "免疫日期",
        vaccineName: "疫苗名称",
        useWay: "用药方式",
        dosage: "药量",
        manufacturer: "疫苗生产厂家",
        batchNum: "厂家批号",
        sceneOperator: "防疫员"
    }
}


export const immunization_en = {
    search: {
        data: {
            title: "Immunization date",
            start: "Start date",
            unit: "to",
            end: "End date"
        },
        time: {
            title: "Registration time",
            start: "Start date",
            unit: "to",
            end: "End date"
        }
    },
    table: {
        Immunization_date: "Immunization date",
        vaccineName: "Vaccine name",
        useWay: "Medication methods",
        dosage: "dosage",
        manufacturer: "Vaccine manufacturers",
        batchNum: "Manufacturer batch number",
        sceneOperator: "Epidemic prevention personnel"
    }
}